import React from 'react';
import {Filter, ReduxState, TextInput} from 'react-admin';
import {renderFilterSelectInput, setProjectFromStore} from '../FiltersUtils';
import {useSelector} from "react-redux";
import {SET_PROJECT, SET_BRANCH} from "redux/types";
import {getItemById} from "utils/common";


const ExperimentsFilters = (props: any) => {
 const { serverConstants, ...rest } = props;
 setProjectFromStore(props);


 const selectedProject = useSelector((state: ReduxState) => state.project);
 const branchesList = selectedProject
   ? serverConstants?.branchesList.filter((item: any) => item.project_id === selectedProject)
   : serverConstants?.branchesList

 const normalizedBranchesList = branchesList.map((value: any) => {
  const projectName = getItemById(serverConstants?.projectsList, value.project_id)?.name;
  return { ...value, name: `${projectName || 'N/A'} | ${value.name}` };
 });

 return (
  <Filter {...rest}>
   {renderFilterSelectInput('project_id', serverConstants?.projectsList, SET_PROJECT)}
   {renderFilterSelectInput('branch_id', normalizedBranchesList, SET_BRANCH)}
   <TextInput source="system_name" resettable alwaysOn parse={(value: string) => value.trim()}/>
   <TextInput source="name" resettable alwaysOn parse={(value: string) => value.trim()}/>
   <TextInput source="screen_name" resettable alwaysOn parse={(value: string) => value.trim()}/>
  </Filter>
 );
};

export default ExperimentsFilters;
