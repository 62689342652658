import React from 'react';

import { withServerConstants } from 'providers/ServerConstantsProvider';
import BranchesNormalizer from 'normalizer/BranchesNormalizer';
import BranchesForm from 'forms/Branches/BranchesForm';
import { ServerConstantsOnSuccess } from '../CommonController';
import { ActionType } from 'interfaces/CommonInterface';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import CreateFromModal from "forms/CreateFromModal";

const BranchesAdd = (props: ControllerPropsInterface) => (
 <CreateFromModal {...props}>
  <BranchesForm
   actionType={ActionType.ADD}
   onSuccess={ServerConstantsOnSuccess}
    transform={BranchesNormalizer.create}
      {...props}
    />
  </CreateFromModal>
);

export default withServerConstants(BranchesAdd);
