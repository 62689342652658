import AbstractNormalizer from './AbstractNormalizer';
import {cleanEmptyParams} from "../validators/Experiments/ExperimentsValidator";

class ProjectsNormalizer extends AbstractNormalizer {
    create(payload: any) {
        payload.gitlab_id = payload.gitlab_id ? Number(payload.gitlab_id) : payload.gitlab_id
        return cleanEmptyParams(payload);
    }

    update(payload: any) {
        const data = super.beforeUpdate(payload);
        data.gitlab_id = payload.gitlab_id ? Number(payload.gitlab_id) : payload.gitlab_id
        delete data.system_name;
        delete data.analytic_db_schema_name;
        return cleanEmptyParams(data);
    }
}

export default new ProjectsNormalizer();
