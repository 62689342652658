import React, { memo } from 'react';
import {
 Edit,
} from 'react-admin';

import ExperimentsNormalizer from 'normalizer/ExperimentsNormalizer';
import ExperimentsForm from 'forms/Experiments';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';

const ExperimentsEdit = (props: ControllerPropsInterface) => {
  return(
 <Edit {...props}>
  <ExperimentsForm
    actionType={ActionType.EDIT}
    onSuccess={defaultOnSuccess}
    redirect="list"
    transform={ExperimentsNormalizer.update}
   {...props}
  />
 </Edit>
)};

export default memo(ExperimentsEdit);
