import React from 'react';
import {required, SelectInput, SortPayload, TextInput, useGetList,} from 'react-admin';

import {withServerConstants} from 'providers/ServerConstantsProvider';
import SimpleFormCustom from '../SimpleFormCustom';
import {ActionType, FormPropsInterface} from "../../interfaces/CommonInterface";

import {validateCyrillic, validateProductCode} from "../../validators/Experiments/ExperimentsValidator";

const ProductCodesForm = (props: FormPropsInterface) => {
  const {
    serverConstants,
    actionType,
    location: {
      state,
    },
  } = props;

  if (actionType === ActionType.ADD) {
    Object.keys(props.record).filter(x => !['id', 'project_id', 'name',].includes(x)).forEach(e => delete props.record[e])
  }
  const projectId = state?.project_id || props?.record?.project_id;
  const featureSets = useGetList(
    'feature-sets',
    {page: 1, perPage: 9999},
    {} as SortPayload,
    {'project_id': projectId}
  );

  return (
    <SimpleFormCustom
      isLoaded={serverConstants?.projectsList}
      {...props}
    >
      <SelectInput
        source="project_id"
        choices={serverConstants?.projectsList}
        initialValue={actionType === ActionType.ADD && state?.project_id}
        validate={[required('Project field is required')]}
        disabled
      />
      <TextInput
        source="name"
        validate={[required('Name field is required'), validateProductCode]}
        parse={(value: string) => value.trim().replace(/ /g, "_")}
      />
      <TextInput
        multiline
        rows={5}
        source="description"
        validate={[validateCyrillic]}
      />
      <SelectInput
        source="feature_set_id"
        name="feature_set_id"
        choices={Object.values(featureSets.data)}
        disabled={actionType === ActionType.EDIT && props.record?.feature_set_id}
        resettable
      />
    </SimpleFormCustom>
  );
};

export default withServerConstants(ProductCodesForm);
