import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router";
import {ActionType, FormPropsInterface} from "../interfaces/CommonInterface";

export const userHasPermission = (permissions, allowedRoles) => {
 let isHasAccess = false;

 allowedRoles.map((item) => {
  if (permissions.includes(item)) {
   isHasAccess = true;
  }

  return false;
 });

 return isHasAccess;
};

export const getItemById = (list, id) => list.find((item) => item.id === id);

export const getChoicesList = (list) => {
 const choicesList = [];

 list.map(({ key, title }) => choicesList.push({ id: key, name: title }));

 return choicesList;
};

export const getProductTrialChoices = () => {
 const trialList = [];

 for (let i = 0; i <= 365; i++) {
  trialList.push({
   id: i,
   name: i !== 0 ? `${i.toString()} ${i !== 1 ? 'days' : 'day'}` : 'No Trial',
  });
 }

 return trialList;
};

export const getProductPeriodChoices = () => {
 const trialList = [];

 for (let i = 1; i <= 365; i++) {
  trialList.push({
   id: i,
   name: `${i.toString()} ${i !== 1 ? 'days' : 'day'}` ,
  });
 }

 return trialList;
};

export const getBranchDefaultRowStyle = (serverConstants, branch_id) => {
 const branch = getItemById(serverConstants.branchesList, branch_id);
 const project = getItemById(serverConstants.projectsList, branch.project_id);

 return {
  borderLeftColor: project.color,
  borderLeftWidth: 10,
  borderLeftStyle: 'solid',
 };
};

export const getDefaultRowStyle = (serverConstants, project_id) => {
 const project = getItemById(serverConstants.projectsList, project_id);

 return {
  borderLeftColor: project.color,
  borderLeftWidth: 10,
  borderLeftStyle: 'solid',
 };
};

export const getDefaultDataGripStyles = makeStyles({
 headerRow: {
  borderLeftColor: 'transparent',
  borderLeftWidth: 10,
  borderLeftStyle: 'solid',
 },
});

export const toTitleCase = (str) => str.replace('_', ' ').replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

export const toFloat = (value) => {
 if ((value || value === 0)) {
  return (value / 100).toString().split('.')[1]?.length > 2
    ? (value / 100).toFixed(2)
    : (value / 100);
 }

 return null;
}

export const toInt = (value) => value ? Math.round(parseFloat(value) * 100) : null;
export   const deleteEmptyKeysFromObj = (inputObject) => Object.fromEntries(
  Object.entries(inputObject).filter(([key, value]) => value !== '')
);