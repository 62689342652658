import React  from 'react';
import {
    TextField,
    EditButton,
     CreateButton,
} from 'react-admin';

import {
 If,
 Choose,
 When,
 Otherwise
} from 'typings/tsx-control-statements.d';

import { getDefaultRowStyle } from 'utils/common';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import List from 'components/List';
import Loader from 'components/Loader';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import {isNotViewer} from "../../utils/authProviderProvider";

const Currencies = (props: ControllerPropsInterface) => {
 const { serverConstants, permissions, ...rest } = props;
  const rowStyle = (record: Record<any, any>) => ({
    borderLeftColor: record.color,
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
    });
 return (
  <Choose>
   <When condition={permissions && !!serverConstants?.projectsList}>
    <List
 actions={isNotViewer(props.permissions) ? ( <CreateButton/>
     ) : false}
     rowStyle={(record) => rowStyle(record)}
     exporter={false}
     {...rest}
     sort={{ field: 'created_at', order: 'DESC' }}
    >
     <TextField
      source="name"
     />
     <TextField
      source="code"
     />
     <TextField
      source="sign"
     />
     <If condition={isNotViewer(permissions)}>
       <EditButton />
          </If>
        </List>
      </When>
     <Otherwise>
        <Loader />
   </Otherwise>
    </Choose>
  );
};

export default withServerConstants(Currencies);
