import React from 'react';
// @ts-ignore
import classNames from 'classnames';
import {required, SortPayload, TextInput, useGetList} from 'react-admin';

import {getItemById} from 'utils/common';
import {withServerConstants} from 'providers/ServerConstantsProvider';

import {ONBOARDING, PAYMENT} from 'constants/screens';

import './FlowsForm.scss';
import SimpleFormCustom from '../SimpleFormCustom';
import {getResponseList} from 'utils/dataProvider';
import {ActionType, FormPropsInterface} from "interfaces/CommonInterface";

import {If} from 'typings/tsx-control-statements.d';
import {PaymentFlowsForm} from "./PaymentFlowsForm";
import {OnboardingFlowsForm} from "./OnboardingFlowsForm";
import {screenValidation} from "validators/Flows/FlowsValidator";
import {validateCyrillic} from "../../validators/Experiments/ExperimentsValidator";

const FlowsForm = (props: FormPropsInterface) => {
 const {
  serverConstants,
  actionType,
  location: {
   state,
   search,
  },
 } = props;
 const screenType = state?.type || props?.record?.type;
 const projectId = state?.project_id || props?.record?.project_id;

 const screensResponse = useGetList(
   'screens',
   {page: 1, perPage: 9999},
   {} as SortPayload,
   {'project_id': projectId, select: true}
 );
 const screensList = getResponseList(screensResponse);
 const onboardingFlowList = screensList.filter((item) => item.type === ONBOARDING);
 const paymentFlowList = screensList.filter((item) => item.type === PAYMENT);
 const postDefaultValue = () => ({ type: screenType });
 if (actionType === ActionType.ADD) {
  const formFields = ['updated_at', 'experiments_count', 'created_at', 'screens_count']
  Object.keys(props.record).filter(x => formFields.includes(x)).forEach(e => delete props.record[e])
 }

 return (
  <SimpleFormCustom
   initialValues={postDefaultValue}
   isLoaded={serverConstants?.projectsList && screensResponse.loaded}
   {...props}
  >
   <TextInput
    source="name"
    validate={[required('Name field is required'), validateCyrillic]}
    parse={(value: string) => value.trim().replace(/ /g,"_")}
   />
   <TextInput
    source="project_id"
    initialValue={actionType === ActionType.ADD && state?.project_id}
    format={(id: number) => getItemById(serverConstants.projectsList, id)?.name}
    disabled
   />
   <TextInput
    multiline
    rows={5}
    source="description"
    validate={[validateCyrillic]}
   />
   <If condition={screenType === ONBOARDING}>
    <OnboardingFlowsForm
     screensList={onboardingFlowList}
    />
   </If>
   <If condition={screenType === PAYMENT}>
    <PaymentFlowsForm
     actionType={actionType}
     screensList={paymentFlowList}
    />
   </If>
  </SimpleFormCustom>
 );
};

export default withServerConstants(FlowsForm);
