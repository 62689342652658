import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import Loader from '../../components/Loader';

const ServerConstantsContext = React.createContext([]);

class serverConstantsProvider extends PureComponent {
 _isMounted = true;

 constructor(props) {
  super(props);
  this.state = {
   projectsList: null,
   branchesList: null,
   productsList: null,
   productCodesList: null,
   info: null,
   isLoaded: false,
  };
 }

 async componentDidMount() {
  try {
   await Promise.all([
    this.loadingInfo(),
    this.loadingProjects(),
    this.loadingBranches(),
    this.loadingProductCodes()
   ])

   this.setState({
    isLoaded: true,
   });
  } catch (e) {
   this.setState({
    isLoaded: true,
   });
  }
 }

 componentWillUnmount() {
  this._isMounted = false;
 }

 loadingScreens = async () => {
  const init = {
   queryStringParameters: {
    page: 1,
    per_page: 9999,
   },
  };

  const { items: screensList } = await API.get('admin', '/admin/screens', init);

  if (this._isMounted) this.setState({ screens: screensList });
 };

 loadingFlows = async () => {
  const init = {
   queryStringParameters: {
    page: 1,
    per_page: 9999,
   },
  };

  const { items: flowsList } = await API.get('admin', '/admin/flows', init);

  if (this._isMounted) this.setState({ flows: flowsList });
 };

 loadingInfo = async () => {
  const data = await API.get('admin', '/admin/info', null);

  if (this._isMounted) this.setState({ info: { ...data } });
 };

 loadingProjects = async () => {
  const init = {
   queryStringParameters: {
    page: 1,
    per_page: 9999,
   },
  };
  const { items: projectsList } = await API.get('admin', '/admin/projects', init);

  if (this._isMounted) this.setState({ projectsList });
 };

 loadingBranches = async () => {
  const init = {
   queryStringParameters: {
    page: 1,
    per_page: 9999,
   },
  };
  const { items: branchesList } = await API.get('admin', '/admin/branches', init);

  if (this._isMounted) this.setState({ branchesList });
 };

  loadingProductCodes = async () => {
  const init = {
   queryStringParameters: {
    page: 1,
    per_page: 9999,
   },
  };
  const { items: productCodesList } = await API.get('admin', '/admin/product-codes', init);

  if (this._isMounted) this.setState({  productCodesList });
 };

 render() {
  const {
   info,
   projectsList,
   branchesList,
   productsList,
    productCodesList,
   screensList,
   flowsList,
  } = this.state;

  const providerValue = {
   loadingProjects: () => this.loadingProjects(),
   loadingBranches: () => this.loadingBranches(),
   projectsList,
   branchesList,
   productsList,
    productCodesList,
   ...info,
  };

  return (
   <ServerConstantsContext.Provider value={providerValue}>
    {
     this.state.isLoaded
      ? this.props.children
      : <div><Loader /></div>
    }
   </ServerConstantsContext.Provider>
  );
 }
}

export const ServerConstantsConsumer = ServerConstantsContext.Consumer;

serverConstantsProvider.propTypes = {
 children: PropTypes.element.isRequired,
};

export default serverConstantsProvider;
