import React from 'react';
import {
 Edit,
} from 'react-admin';

import FlowsNormalizer from 'normalizer/FlowsNormalizer';
import FlowsForm from 'forms/FlowsForm';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';

const FlowsEdit = (props: ControllerPropsInterface) => (
  <Edit {...props}>
  <FlowsForm
    actionType={ActionType.EDIT}
    onSuccess={defaultOnSuccess}
    redirect="list"
    transform={FlowsNormalizer.update}
   {...props}
  />
 </Edit>
);

export default FlowsEdit;
