import React from 'react';
import { Filter } from 'react-admin';
import {renderFilterSelectInput, setProjectFromStore} from '../FiltersUtils';
import {SET_PROJECT} from "../../redux/types";

const RebillRatesSegmentFilters = (props: any) => {
  const { projectsList, productsList, ...rest } = props;
  setProjectFromStore(props);

  return (
    <Filter {...rest}>
      {renderFilterSelectInput('project_id', projectsList, SET_PROJECT)}
    </Filter>
  );
};

export default RebillRatesSegmentFilters;
