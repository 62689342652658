export const DOMAIN_REGEXP = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const PROCESSORS = [
    {
        "id": "solid",
        "name": "Solid"
    },
    {
        "id": "paypal",
        "name": "PayPal"
    },
    {
        "id": "braintree",
        "name": "Braintree"
    }
]
