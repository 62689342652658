import React from 'react';
import {
 SaveButton,
 Toolbar,
} from 'react-admin';
import CheckIcon from '@material-ui/icons/Check';

import './CustomToolbar.scss';

const CustomToolbar = (isError) => (
 <Toolbar className="CustomToolbar" elevation={1}>
  <SaveButton
   label="Continue"
   icon={<CheckIcon />}
   disabled={isError}
  />
 </Toolbar>
);

export default CustomToolbar;
