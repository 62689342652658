import React from 'react';
import {required, TextInput,} from 'react-admin';

import {withServerConstants} from 'providers/ServerConstantsProvider';
import SimpleFormCustom from '../SimpleFormCustom';
import {ActionType, FormPropsInterface} from "../../interfaces/CommonInterface";

import {validateCyrillic} from "../../validators/Experiments/ExperimentsValidator";
import EditToolbar from "../../components/EditToolbar";

const CurrenciesForm = (props: FormPropsInterface) => (
    <SimpleFormCustom
      isLoaded
      toolbar={ <EditToolbar /> }
      {...props}
    >
      <TextInput
        source="name"
        validate={[required('Name field is required'), validateCyrillic]}
        parse={(value: string) => value.trim()}
      />
      <TextInput
        source="code"
        validate={[required('Code field is required'), validateCyrillic]}
        parse={(value: string) => value.trim().replace(/ /g, "_")}
        disabled={props.actionType === ActionType.EDIT}
      />
      <TextInput
        source="sign"
        validate={[required('Sign field is required'), validateCyrillic]}
        parse={(value: string) => value.trim().replace(/ /g, "_")}
        disabled={props.actionType === ActionType.EDIT}
      />
    </SimpleFormCustom>
  )

export default withServerConstants(CurrenciesForm);
