import { HttpError } from "react-admin";

export const getResponseWithErrors = async (query: any) => {
  let response = null;

  try {
    response = await query;
  } catch (err) {
    throw new HttpError(
      err.response.data.error,
      err.response.status,
      err.response.data,
    );
  }

  return { data: { ...response } };
};


export const getResponseList = (dataProviderResponse: any) => {
  const result: any[] = [];
  dataProviderResponse.ids.forEach((id: number) => result.push(dataProviderResponse.data[id]));
  return result;
}
