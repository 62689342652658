import React from 'react';
import {
 Create,
 SimpleForm,
 SelectInput,
 required,
 SelectArrayInput, useNotify, HttpError,
} from 'react-admin';

import Modal from 'components/Modal';
import './SynchronizeRebillRatesModal.scss';
import { useFormState } from 'react-final-form';
import dataProvider from 'providers/DataProvider';
import CustomToolbar from 'modals/components/CustomToolbar';

const ProductInput = ({ productsList }: any) => {
 const { values } = useFormState();
 return (
  <SelectArrayInput
   source="product_ids"
   choices={
    values.project_id ? productsList.filter((product: any) => product.project_id === values.project_id) : productsList
   }
   optionText="uuid"
   allowEmpty
   placeholder="All projects"
   resettable
   fullWidth
  />
 );
};

const SynchronizeRebillRatesModal = ({ isOpen, onCloseModal, projectsList, productsList, ...rest }: any) => {
 const notify = useNotify();
 const handleSubmit = async (data: any) => {
  try {
   await dataProvider.synchronizeRebillRates(data);
   notify('Successfully updated.', 'success');
  } catch (e) {
   let message = 'Something went wrong.';

   if (e instanceof HttpError) {
    message = e.status === 500
     ? 'Something went wrong. Try to select less products.'
     : e.body.error;
   }

   notify(message, 'warning');
  }
 };

 return (
  <Modal
   isOpen={isOpen}
   onCloseModal={onCloseModal}
   modalTitle="Update coefficient"
  >
   <Create {...rest}>
    <SimpleForm
     toolbar={CustomToolbar()}
     save={handleSubmit}
     onSubmit={() => {}}
    >
     <SelectInput
      source="project_id"
      choices={projectsList}
      validate={[required('Project field is required')]}
      resettable
      fullWidth
     />
    </SimpleForm>
   </Create>
  </Modal>
 );
};

export default SynchronizeRebillRatesModal;
