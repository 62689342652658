import React from 'react';
import { Filter, TextInput } from 'react-admin';

import { getChoicesList } from 'utils/common';
import {renderFilterSelectInput, setProjectFromStore} from '../FiltersUtils';
import {SET_PROJECT, SET_SCREEN_TYPE} from "redux/types";

const ScreensFilters = (props: any) => {
 const { serverConstants, ...rest } = props;
 setProjectFromStore(props);

 return (
  <Filter {...rest}>
   {renderFilterSelectInput('project_id', serverConstants?.projectsList, SET_PROJECT)}
   {renderFilterSelectInput('type', getChoicesList(Object.values(serverConstants?.screen_type?.items)), SET_SCREEN_TYPE) }
   <TextInput source="name" resettable alwaysOn parse={(value: string) => value.trim()}/>
   <TextInput source="client_id" resettable alwaysOn parse={(value: string) => value.trim()}/>
  </Filter>
 );
};

export default ScreensFilters;
