import themeReducer from './themeReducer';
import projectReducer from './projectReducer';
import branchReducer from "./branchReducer";
import flowTypeReducer from "./flowTypeReducer";
import screenTypeReducer from "./screenTypeReducer";

const rootReducer = {
  theme: themeReducer,
  project: projectReducer,
  branch: branchReducer,
  flowType: flowTypeReducer,
  screenType: screenTypeReducer
};

export default rootReducer;
