import React from 'react';

import { Edit } from 'react-admin';
import BranchesNormalizer from 'normalizer/BranchesNormalizer';
import BranchesForm from 'forms/Branches/BranchesForm';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import { ServerConstantsOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';

const BranchesEdit = (props: ControllerPropsInterface) => (
 <Edit {...props}>
  <BranchesForm
   actionType={ActionType.EDIT}
   onSuccess={ServerConstantsOnSuccess}
   transform={BranchesNormalizer.update}
   redirect="list"
   {...props}
  />
 </Edit>
);

export default withServerConstants(BranchesEdit);
