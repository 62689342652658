import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
 label: { width: '10em', display: 'inline-block' },
 button: { margin: '1em' },
});

const Settings = () => {
 const classes = useStyles();
 const theme = useSelector((state: {theme: string}) => state.theme);
 const dispatch = useDispatch();

 return (
  <Card>
   <Title title="Personal Settings" />
   <CardContent>
    <div>
     Theme
    </div>
    <Button
     variant="contained"
     className={classes.button}
     color={theme === 'light' ? 'primary' : 'default'}
     onClick={() => dispatch({
      type: 'CHANGE_THEME',
      payload: 'light',
     })}
    >
     Light
    </Button>
    <Button
     variant="contained"
     className={classes.button}
     color={theme === 'dark' ? 'primary' : 'default'}
     onClick={() => dispatch({
      type: 'CHANGE_THEME',
      payload: 'dark',
     })}
    >
     Dark
    </Button>
   </CardContent>
  </Card>
 );
};

export default Settings;
