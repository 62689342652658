import React, { PureComponent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Column from './Column';

import './Table.scss';

const TableTooltipTooltipWrapper = React.forwardRef((props, ref) => <div {...props} ref={ref} />);

const TableTooltip = ({ children, ...props }) => (
 <Tooltip {...props}>
  <TableTooltipTooltipWrapper>{ children }</TableTooltipTooltipWrapper>
 </Tooltip>
);

class Table extends PureComponent {
 renderHead = (columns) => (
  <tr className="Table__row Table__head-row">
   {columns.map(({ props }, index) => (
    <th
     key={`head-${index}`}
     className={classNames('Table__cell Table__head-cell', props.getHeadCellClassName(props.header))}
    >
     <TableTooltip title={props.tooltip || props.header} aria-label="add">
      {props.header}
     </TableTooltip>
    </th>
   ))}
  </tr>
 );

 renderBody = (columns) => {
  const { items } = this.props;

  return items.map((item, rowIndex) => (
   <tr className="Table__row Table__body-row" key={`row-${rowIndex}`}>
    {columns.map(({ props }, columnIndex) => (
     <td
      className={classNames('Table__cell Table__body-cell', props.getBodyCellClassName(item))}
      key={`column-${rowIndex}-${columnIndex}`}
     >
      {props.render(item)}
     </td>
    ))}
   </tr>
  ));
 };

 render() {
  const { tableClassName } = this.props;
  const columns = React.Children.toArray(this.props.children).filter((child) => child.type === Column);

  return (
   <table className={classNames('Table', tableClassName)}>
    <thead className="Table__head">{this.renderHead(columns)}</thead>
    <tbody className="Table__body">{this.renderBody(columns)}</tbody>
   </table>
  );
 }
}

Table.propTypes = {
 children: PropTypes.node.isRequired,
 items: PropTypes.array.isRequired,
 tableClassName: PropTypes.string,
};

Table.defaultProps = {
 tableClassName: '',
};

TableTooltip.propTypes = {
 children: PropTypes.node.isRequired,
};

export default Table;
