import React from 'react';
import {
  TextField,
  NumberField,
  DateField,
  useGetList, useNotify, HttpError, Button, FunctionField, Create, SimpleForm, SelectInput, required
} from 'react-admin';
import {Record} from 'ra-core';

import {
  Choose,
  When,
  Otherwise
} from 'typings/tsx-control-statements.d';

import {getDefaultRowStyle, getItemById} from 'utils/common';
import {withServerConstants} from 'providers/ServerConstantsProvider';
import List from 'components/List';
import Loader from 'components/Loader';
import {ControllerPropsInterface} from 'interfaces/ControllerPropsInterface';
import {getResponseList} from "../../utils/dataProvider";
import {isNotViewer} from "../../utils/authProviderProvider";
import RebillRatesSegmentFilters from "../../filters/RebillRatesSegment/RebillRatesFilters";
import dataProvider from 'providers/DataProvider';


const UpdateSegment = () => {
  const notify = useNotify();
  const handleSubmit = async () => {
    try {
      await dataProvider.synchronizeRebillRatesSegment();
      notify('Successfully updated.', 'success');
    } catch (e) {
      let message = 'Something went wrong.';

      if (e instanceof HttpError) {
        message = e.status === 500
          ? 'Something went wrong. Try to select less products.'
          : e.body.error;
      }

      notify(message, 'warning');
    }
  };

  return (
    <Button
      label="Update segment"
      onClick={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    />
  );
};


const RebillRatesSegment = (props: ControllerPropsInterface) => {
  const {serverConstants, ...rest} = props;
  const productsResponse = useGetList('products', {page: 1, perPage: 9999});
  const projectsList = serverConstants?.projectsList;

  const productsList = getResponseList(productsResponse);
  return (
    <Choose>
      <When condition={!!projectsList && productsResponse.loaded}>
        <List
          actions={isNotViewer(props.permissions) ? <UpdateSegment/>
            : false}
          rowStyle={(record: Record) => getDefaultRowStyle(serverConstants, record.project_id)}
          exporter={false}
          filters={<RebillRatesSegmentFilters projectsList={projectsList} productsList={productsList}/>}
          {...rest}
        > <FunctionField
          source="project_id"
          render={(record: Record | undefined) => getItemById(serverConstants.projectsList, record?.project_id)?.name}
        />
          <TextField source="country_clust" label="Country cluster"/>
          <TextField source="media_source_grp" label="Media source"/>
          <TextField source="gender" label="Gender"/>
          <TextField source="bmi_grp" label="BMI"/>
          <NumberField source="age_grp" label="Age"/>
          <NumberField source="ltv_coef_segment" label="LTV coefficient segment"/>
          <DateField source="updated_at" showTime/>
        </List>
      </When>
      <Otherwise>
        <Loader/>
      </Otherwise>
    </Choose>
  );
};

export default withServerConstants(RebillRatesSegment);
