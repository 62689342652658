import React from 'react';

import {Button, Grid, Typography} from '@material-ui/core';
import {Choose, Otherwise, When} from "../../typings/tsx-control-statements.d";
import Loader from "../../components/Loader";

const BranchesPanel = (props: any) => {
    const {record, screensList, flowsList,} = props;
    return (
      <Choose>
        <When condition={!!record}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                {record.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} container alignContent="flex-end">
              Start page name: {screensList[record.first_page_id]?.name}
            </Grid>
            <Grid item xs={6} container alignContent="flex-end">
              Creation Date: {new Date(record.created_at).toLocaleDateString()}
            </Grid>
            <Grid item xs={6} container alignContent="flex-end">
              Onboarding flow name: {flowsList[record.onboarding_flow_id]?.name}
            </Grid>
            <Grid item xs={6} container alignContent="flex-end">
              Test start Date: {new Date(record.updated_at).toLocaleDateString()}
            </Grid>
            <Grid item xs={6} container alignContent="flex-end">
              Payment flow name: {flowsList[record.payment_flow_id]?.name}
            </Grid>
            <Grid item xs={6} container alignContent="flex-end">
              Created By: {record.created_by}
            </Grid>
            <Grid item xs={6} container alignContent="flex-end">
              Last Updated By: {record.last_updated_by || record.created_by}
            </Grid>
          </Grid>
          <div className="ExperimentsPanel__spacer">&nbsp;</div>
        </When>
        <Otherwise>
          <Loader/>
        </Otherwise>
      </Choose>
    )
  }

;

export default BranchesPanel;
