import React from 'react';

import {Grid} from '@material-ui/core';
import {toFloat} from 'utils/common';
import {Table, Column} from 'components/Table';

import './ScreensPanel.scss';
import {ScreensPanelInterface} from 'interfaces/Screens/ScreensPanelInterface';

import {
    Choose, If,
    When,
} from 'typings/tsx-control-statements.d';

const ScreensPanel = ({productsList, record}: ScreensPanelInterface) => {
    if (!!record.products.length) {
        record?.products?.map((product: any, index: number) => {
            if (record?.products[index]?.prices?.length) {
                const prices = record?.products[index]?.prices?.map((price: any) => ({
                        "product_id": record?.products[index].product_id,
                        "price_per_state": price.price_per_state,
                        "location": price.location,
                        "price": price.price,
                        "sign": price.sign,
                        "code": price.code,
                        "start_price": price.start_price,
                        "start_price_per_state": price.start_price_per_state
                    }
                ))
                record?.products.splice(index, 1);
                record?.products.push(...prices);
            }
        })
    }
        return (
            <>
                <Grid item xs={6} container alignContent="flex-end">
                    Description: {record.description || 'N/A'}
                </Grid>
                <If condition={record.config && !Array.isArray(record.config)}>
                    <Grid item xs={6} container alignContent="flex-end">
                        Config: {record.config}
                    </Grid>
                </If>
                <If condition={Array.isArray(record.config)}>
                    <Grid item xs={6} container alignContent="flex-end">
                        Config (deprecated config type):
                    </Grid>
                    {record.config.map((item: any) =>
                        <Grid item xs={6} container alignContent="flex-end">
                            {`${item.key}: ${item.value}`}
                        </Grid>)}
                </If>
                <Grid item container alignContent="flex-end">
                    <Choose>
                        <When condition={!!record.products.length}>
                            <Table
                                items={record.products}
                                tableClassName="ScreensPanel"
                            >
                                <Column
                                    header="Name"
                                    render={({product_id}) => productsList[product_id]?.name}
                                />
                                <Column
                                    header="UUID"
                                    render={({product_id}) => productsList[product_id]?.uuid}
                                />
                                <Column
                                    header="Start Price"
                                    render={({start_price}) => toFloat(start_price)}
                                />
                                <Column
                                    header="Price"
                                    render={({price, product_id}) => toFloat(price? price : productsList[product_id]?.price)}
                                />
                                <Column
                                    header="Start Price per State"
                                    render={({start_price_per_state}) => toFloat(start_price_per_state)}
                                />
                                <Column
                                    header="Price per State"
                                    render={({price_per_state}) => toFloat(price_per_state)}
                                />
                                <Column
                                    header="Location"
                                    render={({location}) => location}
                                />
                                <Column
                                    header="Currency code"
                                    render={({code}) => code}
                                />
                                <Column
                                    header="Type"
                                    render={({product_id}) => productsList[product_id]?.type}
                                />
                                <Column
                                    header="Period(days)"
                                    render={({product_id}) => productsList[product_id]?.period}
                                />
                                <Column
                                    header="Trial(days)"
                                    render={({product_id}) => productsList[product_id]?.trial}
                                />
                            </Table>
                        </When>
                    </Choose>
                </Grid>
            </>
        );
    }

export default ScreensPanel;
