import React from 'react';
import PropTypes from 'prop-types';
import {
  SimpleForm,
  SelectInput,
   useNotify,
} from 'react-admin';

import Modal from 'components/Modal';
import CustomToolbar from '../components/CustomToolbar';

import './RunTestModal.scss';
import {deleteEmptyKeysFromObj, getItemById} from "../../utils/common";
import dataProvider from "../../providers/DataProvider";

const RunTestModal = ({isOpen, onCloseModal, modalTitle, serverConstants, item, ...rest}: any) => {


  const notify = useNotify();
  const handleRunTest = async (variant: any) => {
    const branch = getItemById(serverConstants.branchesList, rest.branch_id);
    let cleaFormData = deleteEmptyKeysFromObj(variant)
      cleaFormData['branch_name'] = branch.url_segment
      cleaFormData['test_name'] = rest.system_name
      cleaFormData['variant_name'] = item.name

    try {
      await dataProvider.runExperimentPipline(branch.project_id, cleaFormData)
      notify('Pipeline created', 'success');
    } catch (e) {
      notify('Something went wrong.', 'warning');
    }
    onCloseModal()
  };

  return (
    <Modal
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      modalTitle={modalTitle}
    >
      <SimpleForm
        toolbar={CustomToolbar()}
        save={handleRunTest}
        onSubmit={() => {
        }}
      >
        <SelectInput
          source="buy_first"
          choices={[
            {id: 'true', name: 'True'},
            {id: 'false', name: 'False'},
          ]}
          initialValue={''}
          resettable
        />
        <SelectInput
          source="only_ob"
          choices={[
            {id: 'true', name: 'True'},
            {id: 'false', name: 'False'},
          ]}
          initialValue={''}
          resettable
        />
        <SelectInput
          source="is_email"
          choices={[
            {id: 'true', name: 'True'},
            {id: 'false', name: 'False'},
          ]}
          initialValue={''}
          resettable
        />
          <SelectInput
          source="gender"
          choices={[
            {id: 'male', name: 'male'},
            {id: 'female', name: 'female'},
          ]}
          initialValue={''}
          resettable
        />
        <SelectInput
          source="proxy"
          choices={[
            {"id": "de", "name": "de"},
            {"id": "br", "name": "br"},
            {"id": "ar", "name": "ar"},
            {"id": "co", "name": "co"},
            {"id": "gb", "name": "gb"},
            {"id": "mx", "name": "mx"},
            {"id": "cl", "name": "cl"},
            {"id": "pe", "name": "pe"},
            {"id": "tw", "name": "tw"},
            {"id": "ph", "name": "ph"},
            {"id": "za", "name": "za"},
            {"id": "ca", "name": "ca"},
            {"id": "pl", "name": "pl"},
            {"id": "tr", "name": "tr"},
            {"id": "us/california", "name": "us/california"},
            {"id": "us", "name": "us"},
            {"id": "au", "name": "au"}
          ]}
          initialValue={''}
          resettable
        />
        <SelectInput
          source="language"
          choices={[
            {"id": "es", "name": "es"},
            {"id": "en", "name": "en"},
            {"id": "fr", "name": "fr"},
            {"id": "de", "name": "de"},
            {"id": "it", "name": "it"},
            {"id": "pt", "name": "pt"},
            {"id": "ja", "name": "ja"},
            {"id": "pl", "name": "pl"},
            {"id": "tr", "name": "tr"},
            {"id": "ko", "name": "ko"}
          ]}
          initialValue={''}
          resettable
        />
          <SelectInput
          source="payment"
          choices={[
            {id: 'CreditCard', name: 'CreditCard'},
            {id: 'Paypal', name: 'Paypal'},
          ]}
          initialValue={''}
          resettable
        />
          <SelectInput
          source="buy_additional"
          choices={[
            {id: 'true', name: 'True'},
            {id: 'false', name: 'False'},
          ]}
          initialValue={''}
          resettable
        />
      </SimpleForm>
    </Modal>
  );
};

RunTestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  serverConstants: PropTypes.object.isRequired,
};

export default RunTestModal;
