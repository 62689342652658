import { AnyAction } from 'redux'
import { SET_SCREEN_TYPE } from "./types";

const screenTypeReducer = (previousState: number | null = null, action: AnyAction) => {
  if (action.type === SET_SCREEN_TYPE) {
    return action.payload;
  }
  return previousState;
};

export default screenTypeReducer;
