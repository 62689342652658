import React from 'react';
import {
 Edit,
} from 'react-admin';

import ProductsNormalizer from 'normalizer/ProductsNormalizer';
import ProductsForm from 'forms/Products/ProductsForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';

const ProductsEdit = (props: ControllerPropsInterface) => (
 <Edit {...props}>
  <ProductsForm
    actionType={ActionType.EDIT}
    onSuccess={defaultOnSuccess}
    redirect="list"
    transform={ProductsNormalizer.update}
   {...props}
  />
 </Edit>
);

export default ProductsEdit;
