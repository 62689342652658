import AbstractNormalizer from './AbstractNormalizer';

class ScreensNormalizer extends AbstractNormalizer {
  private normalizeProducts(products: any[]) {
    const newProductList: any[] = [];

    products.forEach((item) => {
      const newItem = item;
      delete newItem.price;
      newItem.start_price_per_state = Math.round(parseFloat(newItem.start_price_per_state));
      newProductList.push(newItem);
    });

    return newProductList;
  };

  public create(payload: any) {
    const data = payload;

    if (data.products) {
      // const newProductList = this.normalizeProducts(data.products);
      const newProductList: any[] = [];

      data.products.forEach((item: any) => {
        const newItem = item;
        delete newItem.price;
        // newItem.start_price_per_state = Math.round(parseFloat(newItem.start_price_per_state));
        newProductList.push(newItem);
      });

      return {
        ...data,
        products: newProductList,
      };
    }

    return data;
  }

  public update(payload: any) {
    const data = super.beforeUpdate(payload);
    delete data.project_id;
    delete data.type;
    delete data.experiments_count;

    if (!data.products.length) {
      delete data.products;
      delete data.product_state;
    }
    if (data.products) {
      const newProductList: any[] = [];

      data.products.forEach((item: any) => {
        const newItem = item;
        delete newItem.price;
        // newItem.start_price_per_state = Math.round(parseFloat(newItem.start_price_per_state));
        newProductList.push(newItem);
      });

      return {
        ...data,
        products: newProductList,
      };
    }

    return data;
  }
}

export default new ScreensNormalizer();
