import React from 'react';
import {
 MenuItemLink,
 getResources,
 ReduxState,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// @ts-ignore
import classnames from 'classnames';
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
 root: {
  marginTop: theme.spacing(1),
  transition: theme.transitions.create('width', {
   easing: theme.transitions.easing.sharp,
   duration: theme.transitions.duration.leavingScreen,
  }),
 },
 open: {
  width: 200,
 },
 closed: {
  width: 55,
 },
}));

const Menu = () => {
 const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
 const resources = useSelector(getResources);
 const classes = useStyles();

 return (
  <Box
   mt={1}
   className={classnames(classes.root, {
    [classes.open]: open,
    [classes.closed]: !open,
   })}
  >
   {resources.map((resource) => (
    <MenuItemLink
     key={resource.name}
     to={`/${resource.name}`}
     primaryText={resource?.options?.label}
     sidebarIsOpen={open}
     leftIcon={resource.icon}
    />
   ))}
  </Box>
 );
};

export default Menu;
