import { PaletteType } from '@material-ui/core';

export const darkTheme = {
  palette: {
    primary: {
      main: '#28cde8',
    },
    secondary: {
      main: '#ffbb50',
    },
    type: 'dark' as PaletteType,
  },
  sidebar: {
    width: 200,
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: '#fff',
        backgroundColor: '#28cde8',
      },
    },
    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit',
        },
      },
    },
    MuiToolbar: {
      root: {
        padding: '0 !important',
        alignItems: 'center !important',
      },
    },
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: '#5e7ae7',
    },
    secondary: {
      main: '#ffbb50',
    },
    type: 'dark' as PaletteType,
  },
  sidebar: {
    width: 200,
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: '#fff',
        backgroundColor: '#eeb034',
      },
    },
    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit',
        },
      },
    },
    MuiToolbar: {
      root: {
        padding: '0 !important',
        alignItems: 'center !important',
      },
    },
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true,
    },
  },
};
