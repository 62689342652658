import React, {ComponentType} from 'react';
import {LayoutProps, Layout, ReduxState} from 'react-admin';
import { useSelector } from 'react-redux';

import Menu from 'components/Menu';
import CustomAppBar from 'components/AppBar';
import { lightTheme, darkTheme } from './themes';
import config from "../../config";

const MainLayout = (props: ComponentType<LayoutProps>) => {
 let theme = useSelector((state: ReduxState) => state.theme === 'light' ? lightTheme : darkTheme);

 return (
  <Layout
   {...props}
   menu={Menu}
   appBar={CustomAppBar}
   theme={config.LAMBDA_STAGE === "stage" ? lightTheme : theme}
  />
 );
};

export default MainLayout;
