import React from 'react';
import {
 ArrayInput,
 FormDataConsumer,
 NumberInput,
 required,
 SelectInput,
 SimpleFormIterator,
 SortPayload,
 TextInput,
 useGetList
} from 'react-admin';

import { withServerConstants } from 'providers/ServerConstantsProvider';
import { getItemById } from 'utils/common';

import './ExperimentsForm.scss';
import SimpleFormCustom from '../SimpleFormCustom';
import { getResponseList } from '../../utils/dataProvider';
import {ONBOARDING, PAYMENT} from "../../constants/flows";
import {START_PAGE} from "../../constants/screens";
import {
 previousNameValidator,
 validateCyrillic, validatePage,
 validateTraffic, validateVariantName
} from "../../validators/Experiments/ExperimentsValidator";
import {ActionType} from "../../interfaces/CommonInterface";

const ExperimentsForm = (props: any) => {
 const {
  serverConstants,
  actionType,
  location: {state},
  record,
 } = props;

 const branch = getItemById(serverConstants.branchesList, state?.branch_id);
 const projectId = record.project_id ? record.project_id : branch?.project_id;

 const startScreenResponse = useGetList(
   'screens',
   {page: 1, perPage: 9999},
   {} as SortPayload,
   {'project_id': projectId, 'type': START_PAGE}
 );
 const flowsResponse = useGetList(
   'flows',
   {page: 1, perPage: 9999},
   {} as SortPayload,
   {'project_id': projectId}
 );

 const startScreenList = getResponseList(startScreenResponse);
 const flowsList = getResponseList(flowsResponse);

 const onboardingFlowList = flowsList.filter((item) => item.type === ONBOARDING);
 const paymentFlowList = flowsList.filter((item) => item.type === PAYMENT);

 return (
  <SimpleFormCustom
   isLoaded={serverConstants?.projectsList && startScreenResponse.loaded && flowsResponse.loaded}
   {...props}
    initialValues={{'variants': [{
     name: 'Control',
     first_page_id: branch?.first_page_id,
     onboarding_flow_id: branch?.onboarding_flow_id,
     payment_flow_id: branch?.payment_flow_id,
     traffic_percent: 100,
    }]}}
  >
   <SelectInput
    source="branch_id"
    choices={serverConstants?.branchesList}
    initialValue={actionType === ActionType.ADD && state?.branch_id}
    validate={[required('Branch field is required')]}
    disabled
   />
   <TextInput
    source="name"
    parse={(value: string) => value.trim().replace(/ /g,"_")}
    validate={[
     required('Name field is required'),
     previousNameValidator(record.name || undefined),
      validateCyrillic
    ]}
   />
   <TextInput
    multiline
    rows={5}
    source="description"
    validate={[validateCyrillic]}
   />
   <ArrayInput
    source="variants"
    validate={[required()]}
   >
    <SimpleFormIterator>
     <FormDataConsumer>
      {({ getSource }) => (
       <>
        <TextInput
         key="name"
         parse={(value: string) => value.trim().replace(/ /g,"_")}
         source={getSource ? getSource('name') : 'default'}
         label="Name"
         className="ExperimentsForm__input"
         validate={[required('Name field is required'), validateCyrillic, validateVariantName]}
        />
        <SelectInput
         key="first_page_id"
         label="Start Page"
         source={getSource ? getSource('first_page_id') : 'default'}
         choices={startScreenList}
         className="ExperimentsForm__input"
         validate={[validatePage]}
        />
        <SelectInput
         key="onboarding_flow_id"
         label="Onboarding flow"
         source={getSource ? getSource('onboarding_flow_id') : 'default'}
         choices={onboardingFlowList}
         className="ExperimentsForm__input"
         validate={[validatePage]}
        />
        <SelectInput
         key="payment_flow_id"
         label="Payment flow"
         source={getSource ? getSource('payment_flow_id') : 'default'}
         choices={paymentFlowList}
         className="ExperimentsForm__input"
         validate={[validatePage]}
        />
        <NumberInput
         key="traffic_percent"
         source={getSource ? getSource('traffic_percent') : 'default'}
         label="Traffic %"
         min={0}
         max={100}
         validate={[
           required('Traffic field is required'),
           validateTraffic
         ]}
        />
       </>
       )}
     </FormDataConsumer>
    </SimpleFormIterator>
   </ArrayInput>
  </SimpleFormCustom>
 );
};

export default withServerConstants(ExperimentsForm);
