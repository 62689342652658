import React from 'react';

import CurrenciesForm from 'forms/Currencies/CurrenciesForm';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import CreateFromModal from "forms/CreateFromModal";
import CurrenciesNormalizer from 'normalizer/CurrenciesNormalizer';


const CurrenciesAdd = (props: ControllerPropsInterface) => (
 <CreateFromModal {...props}>
  <CurrenciesForm
    actionType={ActionType.ADD}
    onSuccess={defaultOnSuccess}
    transform={CurrenciesNormalizer.create}
    {...props}
  />
 </CreateFromModal>
);

export default CurrenciesAdd;
