import { FormDataConsumer, required, SelectInput } from 'react-admin';
import React from 'react';
import { ActionType } from "interfaces/CommonInterface";
// @ts-ignore
import classNames from 'classnames';
import {PaymentFlowFormInterface, PaymentFlowSelectInputInterface} from "interfaces/Flows/PaymentFlowInterface";

const makePaymentSelect = (levelsCount: number, formData: any, actionType: ActionType) => {
  const items: PaymentFlowSelectInputInterface[][] = [];
  let index = 0;

  for (let i = 0; i < levelsCount; i++) {
    items[i] = [];
    // eslint-disable-next-line no-restricted-properties
    const inputCount = 2 ** i;

    for (let j = 0; j < inputCount; j++) {
      const childLabel = j % 2 === 0 ? 'Screen (buy)' : 'Screen (not buy)';
      // Make SelectInput config
      items[i][j] = {
        source: `payment_screens[${index}].screen_id`,
        label: i === 0 ? 'Screen' : childLabel,
        showArrows: i !== levelsCount - 1,
      };

      if (index === 0) {
        items[i][j].validate = [required('Screen field is required')];
      } else {
        const isEven = index % 2 === 0;
        const parentId = ((isEven ? index : index + 1) / 2) - 1;
        const disabledAddLevel = (actionType !== ActionType.EDIT && !formData.payment_screens)
          || (actionType === ActionType.ADD && !formData.payment_screens[parentId]);
        const disabledEditLevel = actionType === ActionType.EDIT && !formData.payment_screens[parentId]?.screen_id;
        items[i][j].disabled = disabledAddLevel || disabledEditLevel;
      }
      index++;
    }
  }

  return items;
};

const getPaymentTree = (formData: any, renderPaymentSelect: any, actionType: ActionType) => {
  const items = makePaymentSelect(4, formData, actionType);

  return (
    <div className="FlowsForm__payment">
      {items.map((item) => (
        <div className="FlowsForm__payment-col">
          {item.map((value) => renderPaymentSelect(value))}
        </div>
      ))}
    </div>
  );
};

export const PaymentFlowsForm = ({ actionType, screensList }: PaymentFlowFormInterface) => {
  const renderPaymentSelect = ({
   source = 'default_screen_source',
   label = '',
   validate = null,
   showArrows = false,
   disabled = false,
  }: PaymentFlowSelectInputInterface) => (
   <SelectInput
    key={source}
    source={source}
    label={label}
    choices={screensList}
    validate={validate}
    className={classNames('FlowsForm__payment-select', { 'up down': showArrows })}
    resettable
    disabled={disabled}
   />
  );

  return (
    <FormDataConsumer>
      {({ formData }) => getPaymentTree(formData, renderPaymentSelect, actionType)}
    </FormDataConsumer>
  );
}
