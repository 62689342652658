import React from 'react';
import {
    TextInput,
    required,
    regex, SelectInput,
} from 'react-admin';
// @ts-ignore
import {ColorInput} from 'react-admin-color-input';
import {ActionType, FormPropsInterface} from '../../interfaces/CommonInterface';
import SimpleFormCustom from '../SimpleFormCustom';
import {DOMAIN_REGEXP} from "constants/common";
import EditToolbar from "../../components/EditToolbar";
import {validateCyrillic, isNumber} from "../../validators/Experiments/ExperimentsValidator";
import {NUEVO_PROJECT_LIST} from "../../utils/constants";
import {MenuItem} from "@mui/material";

const ProjectsForm = (props: FormPropsInterface) => (
    <SimpleFormCustom
        isLoaded={true}
        {...props}
        toolbar={<EditToolbar/>}
    >
        <TextInput
            source="name"
            validate={[required('Name field is required'), validateCyrillic]}
            isLoaded={true}
            parse={(value: string) => value.trim().replace(/ /g, "_")}

        />
        <TextInput
            source="system_name"
            validate={[required('System name field is required'), validateCyrillic]}
            disabled={props.actionType === ActionType.EDIT}
            parse={(value: string) => value.trim().replace(/ /g, "_")}

        />
        {props.actionType === ActionType.ADD ? <TextInput
            source="analytic_db_schema_name"
            validate={[required('DB schema name is required'), validateCyrillic]}
            parse={(value: string) => value.trim().replace(/ /g, "_")}

        /> : null}
        <TextInput
            source="gitlab_id"
            label="Gitlab ID"
            validate={[isNumber]}

        />
        <TextInput
            source="nuevo_id"
            label="Nuevo ID"
            select
            resettable
        >
            {NUEVO_PROJECT_LIST.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextInput>
        <TextInput
            source="domain"
            parse={(value: string) => value.trim().replace(/ /g, "_")}
            validate={[
                regex(DOMAIN_REGEXP, 'Invalid format'),
                required('Domain field is required'),
                validateCyrillic
            ]}
        />
        <ColorInput
            source="color"
            validate={[required('Color field is required')]}
        />
    </SimpleFormCustom>
);

export default ProjectsForm;
