import React, { useState } from 'react';
import {
 TextField,
 FunctionField,
 EditButton,
 CloneButton,
 useGetList,
  DeleteWithConfirmButton
} from 'react-admin';

import {
  If,
  Choose,
  When,
  Otherwise
} from 'typings/tsx-control-statements.d';

import { getBranchDefaultRowStyle, getItemById } from 'utils/common';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import CreateExperimentModal from 'modals/CreateExperimentModal';
import ShowExperimentDetails from 'modals/ShowExperimentDetails';
import SplitExperimentTrafficModal from 'modals/SplitExperimentTrafficModal';
import List from 'components/List';
import Loader from 'components/Loader';
import ExperimentsPanel from 'panels/Experiments/ExperimentsPanel';
import ExperimentsFilters from 'filters/Experiments/ExperimentsFilters';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { Record } from 'ra-core';
import CreateWithModalAction from 'actions/CreateWithModalAction';
import {isNotViewer} from "../../utils/authProviderProvider";

const Experiments = (props: ControllerPropsInterface) => {
 const { serverConstants, permissions, ...rest } = props;

 const [isOpen, setIsOpen] = useState(false);
 const [isOpenVariant, setIsOpenVariant] = useState(false);
 const [variantData, setVariantData] = useState({});
 const [isOpenSplitTraffic, setIsOpenSplitTraffic] = useState(false);
 const [splitTrafficData, setSplitTrafficData] = useState({});

 return (
  <Choose>
   <When condition={permissions && !!serverConstants?.branchesList && !!serverConstants?.projectsList}>
    <>
     <List
      rowStyle={(record: Record) => getBranchDefaultRowStyle(serverConstants, record.branch_id)}
      rowClick="expand"
      expand={ ({ record }) => (
       <ExperimentsPanel
        serverConstants={serverConstants}
        setIsOpenVariant={setIsOpenVariant}
        setVariantData={setVariantData}
        record={record}
       />
      )}
      actions={isNotViewer(permissions) ? (
       <CreateWithModalAction
        openModal={() => setIsOpen(true)}
       />
      ) : false}
      filters={<ExperimentsFilters serverConstants={serverConstants} />}
      {...rest}
    sort={{ field: 'created_at', order: 'DESC' }}
     >
      <FunctionField
       source="project_id"
       render={(record: Record | undefined) => {
        const branch = getItemById(serverConstants.branchesList, record?.branch_id);
        return getItemById(serverConstants.projectsList, branch.project_id)?.name;
       }}
      />
      <FunctionField
       source="branch_id"
       render={(record: Record | undefined) => getItemById(serverConstants.branchesList, record?.branch_id)?.name}
      />
      <TextField source="system_name" />
      <TextField source="name" />
      <If condition={isNotViewer(permissions)}>
       <EditButton label="Recreate" />
       <CloneButton label="Copy" />
       <FunctionField
        render={(record: Record | undefined) => (
         <EditButton
          label="Split Traffic"
          onClick={(e) => {
           e.preventDefault();
           setSplitTrafficData(record || {});
           setIsOpenSplitTraffic(true);
          }}
         />
        )}
       />
       <DeleteWithConfirmButton confirmTitle={'Delete experiment'}/>
      </If>
     </List>
      {/*<ShowExperimentDetails*/}
      {/* modalTitle="Experiment Details"*/}
      {/* isOpen={isOpenVariant}*/}
      {/* onCloseModal={() => setIsOpenVariant(false)}*/}
      {/* data={variantData}*/}
      {/* {...rest}*/}
      {/*/>*/}
     <If condition={isNotViewer(permissions)}>
      <CreateExperimentModal
       modalTitle="Create Experiment"
       isOpen={isOpen}
       onCloseModal={() => setIsOpen(false)}
       serverConstants={serverConstants}
       {...rest}
      />
      <SplitExperimentTrafficModal
       modalTitle="Split Traffic"
       isOpen={isOpenSplitTraffic}
       onCloseModal={() => setIsOpenSplitTraffic(false)}
       record={splitTrafficData}
       {...rest}
      />
     </If>
    </>
   </When>
   <Otherwise>
    <Loader />
   </Otherwise>
  </Choose>
 );
};

export default withServerConstants(Experiments);
