import React from 'react';
import {
 Edit,
} from 'react-admin';

import CurrenciesForm from 'forms/Currencies/CurrenciesForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import CurrenciesNormalizer from 'normalizer/CurrenciesNormalizer';

const CurrenciesEdit = (props: ControllerPropsInterface) => (
 <Edit {...props}>
  <CurrenciesForm
    actionType={ActionType.EDIT}
    onSuccess={defaultOnSuccess}
    redirect="list"
    transform={CurrenciesNormalizer.update}
   {...props}
  />
 </Edit>
);

export default CurrenciesEdit;
