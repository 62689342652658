import React from 'react';
import { useForm } from 'react-final-form';
import {RecordMap, required, SelectInput} from 'react-admin';
import { getChoicesList } from 'utils/common';
import { processPerState} from './screensUtils';
import {FormApi} from "final-form";
import {ScreensProductStateInterface} from "../../interfaces/Screens/ScreensProductStateInterface";

const onChange = (form: FormApi, productsResponse: RecordMap) => {
    const { products,  product_state} = form.getState().values;
    products?.forEach((product: any, productIndex: number) => {
        const productId = product.product_id;
        const productData = productsResponse.data[productId];

        processPerState(form,productData,product_state,productIndex)
    });
};

const ProductStateInput = ({ serverConstants, productsResponse, form }: ScreensProductStateInterface) => {
    return (
        <SelectInput
            label="State"
            source="product_state"
            choices={getChoicesList(Object.values(serverConstants.product_state_type.items))}
            resettable
            onChange={() => onChange(form, productsResponse)}
            validate={[required('State field is required')]}
        />
    );
};

export default ProductStateInput;