import * as React from 'react';
import { PropsWithChildren, forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, AppBarProps, UserMenuProps } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import './AppBar.scss';

const useStyles = makeStyles({
 title: {
  flex: 1,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
 },
 spacer: {
  flex: 1,
 },
});

const CustomUserMenu = (props: UserMenuProps) => (
 <UserMenu {...props}>
   <MenuItemLink
     to="/settings"
     primaryText="Personal Setting"
     leftIcon={<SettingsIcon />}
     sidebarIsOpen
   />
 </UserMenu>
);

const CustomAppBar = (props: PropsWithChildren<AppBarProps>) => {
 const classes = useStyles();
 return (
  <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
   <Typography
    variant="h6"
    color="inherit"
    className={classes.title}
    id="react-admin-title"
   />
   <img className="AppBar__logo" alt="header-logo" />
   <span className={classes.spacer} />
  </AppBar>
 );
};

export default CustomAppBar;
