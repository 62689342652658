import React, {useState} from 'react';
import {
  NumberInput,
  required,
  SelectInput,
  SortPayload,
  TextInput,
  useGetList,
  ArrayInput, SimpleFormIterator, FormDataConsumer, BooleanInput, RecordMap
} from 'react-admin';

import {withServerConstants} from 'providers/ServerConstantsProvider';
import {getChoicesList, getProductPeriodChoices, getProductTrialChoices, toFloat, toInt} from 'utils/common';
import SimpleFormCustom from '../SimpleFormCustom';
import {ActionType, FormPropsInterface} from "../../interfaces/CommonInterface";

import {validateCyrillic} from "../../validators/Experiments/ExperimentsValidator";
import {PROCESSORS} from "../../constants/common";
import {getResponseList} from "../../utils/dataProvider";
import {COUNTRIES_LIST} from "../../utils/constants";
import {useForm} from 'react-final-form';
import {FormApi} from "final-form";
import EditDeleteToolbar from "../../components/EditDeleteToolbar";


const ProductsForm = (props: FormPropsInterface) => {
  const {
    serverConstants,
    actionType,
      record,
    location: {
      state,
    },
  } = props;
  if (actionType === ActionType.ADD) {
    const formFields = ['id', 'uuid', 'project_id', 'name', 'type', 'period', 'price', 'trial', 'description', 'product_code_ids']
    Object.keys(props.record).filter(x => !formFields.includes(x)).forEach(e => delete props.record[e])
  }
  const projectId = state?.project_id ? state?.project_id : props?.record?.project_id;
  const productCodeResponse = useGetList(
    'product-codes',
    {page: 1, perPage: 9999},
    {} as SortPayload,
    {'project_id': projectId}
  )
  const productCodeList = getResponseList(productCodeResponse);
  const currencyResponse = useGetList(
    'currencies',
    {page: 1, perPage: 9999},
    {} as SortPayload,
  );

  const onChangeDefault = (form: FormApi, e: any) => {
    const formData = form.getState()
    const active_index = formData.active?.replace(/[^\d]/g, '') || 0;
    formData.values.prices.forEach((price: any, index: number) => {
      if (index === active_index) {
        form.change(`prices[${active_index}].is_default`, e);
      } else {
        form.change(`prices[${index}].is_default`, false);
        form.change(`prices[${active_index}].is_default`, e);
      }
    });
  };
  return (
    <SimpleFormCustom
      isLoaded={serverConstants?.projectsList}
      {...props}
        toolbar={
        actionType === ActionType.EDIT ? (
          <EditDeleteToolbar
            alwaysEnableSaveButton={false}
            record={record}
          />
        ) : undefined
      }
    >
      <TextInput
        source="uuid"
        parse={(value: string) => value.trim().replace(/ /g, "_")}
        validate={[required('Uuid field is required'), validateCyrillic]}
      />
      <SelectInput
        source="project_id"
        choices={serverConstants?.projectsList}
        initialValue={actionType === ActionType.ADD && state?.project_id}
        validate={[required('Project field is required')]}
        disabled
      />
      <TextInput
        source="name"
        validate={[required('Name field is required'), validateCyrillic]}
      />
      <SelectInput
        source="type"
        choices={getChoicesList(Object.values(serverConstants?.product_type?.items))}
        validate={[required('Type field is required')]}
        resettable
      />
      <SelectInput
        source="period"
        label="Period(days)"
        choices={getProductPeriodChoices()}
        validate={[required('Period field is required')]}
      />
      <SelectInput
        source="trial"
        choices={getProductTrialChoices()}
        validate={[required('Type field is required')]}
        resettable
      />
      <SelectInput
        source="product_code_ids"
        label="Product code"
        choices={productCodeList}
        validate={[required('Product code field is required')]}
        resettable
      />
      <SelectInput
        key="processor"
        source="processor"
        choices={PROCESSORS}
        allowEmpty={false}
        validate={[required('Processor field is required')]}
        resettable
        alwaysOn
      />
      <TextInput
        multiline
        rows={5}
        source="description"
        validate={[validateCyrillic]}
      />
      <ArrayInput source="prices" validate={[required('Prices field is required')]}>
        <SimpleFormIterator>
          <FormDataConsumer>
            {({scopedFormData, getSource}) => {
              const form = useForm();
              const currencySign = currencyResponse.data[scopedFormData?.currency_id]?.sign;
              return (
                <>
                  <NumberInput
                    source={getSource ? getSource('price') : 'default'}
                    label="Price"
                    validate={[required('Price field is required')]}
                    style={{marginRight: '15px'}}
                    parse={(value: number) => toInt(value)}
                    format={(value: number) => toFloat(value)}
                    onWheel={() => {
                    }}
                    min={0}
                    step={0.01}
                  />
                  <NumberInput
                    source={getSource ? getSource('start_price') : 'default'}
                    label="Start price"
                    validate={[required('Start price field is required')]}
                    style={{marginRight: '15px'}}
                    parse={(value: number) => toInt(value)}
                    format={(value: number) => toFloat(value)}
                    onWheel={() => {
                    }}
                    min={0}
                    step={0.01}
                  />
                  <SelectInput
                    key={scopedFormData?.currency_id}
                    label="Currency"
                    source={getSource ? getSource('currency_id') : 'default'}
                    choices={getResponseList(currencyResponse)}
                    style={{marginRight: '15px'}}
                    validate={[required('Currency field is required')]}
                  />
                  <TextInput
                    key={scopedFormData?.sign}
                    source={getSource ? getSource('sign') : 'default'}
                    label="Sign"
                    style={{marginRight: '15px'}}
                    initialValue={currencySign}
                    disabled
                  />
                  <SelectInput
                    key={scopedFormData?.location}
                    label="Locations"
                    source={getSource ? getSource('location') : 'default'}
                    choices={COUNTRIES_LIST}
                  />
                  <BooleanInput
                    label="Default price"
                    source={getSource ? getSource('is_default') : 'default'}
                    onChange={(e) => {
                      onChangeDefault(form, e)
                    }}
                  />
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleFormCustom>
  );
};

export default withServerConstants(ProductsForm);
