import { createAddPaymentFlowStructure, createEditPaymentFlowStructure } from 'utils/convertPaymentFlow';
import AbstractNormalizer from './AbstractNormalizer';
import {PAYMENT} from "../constants/flows";

class FlowsNormalizer extends AbstractNormalizer {
  create(payload: any) {
    const data = payload;
    delete data.created_by;
    delete data.last_updated_by;

    if (data.type === PAYMENT) {
      data.payment_screens = createAddPaymentFlowStructure(data)
      delete data.onboarding_screens;
    } else {
      delete data.payment_screens;
    }

    return data;
  }

  update(payload: any) {
    const data = super.beforeUpdate(payload);
    delete data.project_id;
    delete data.experiments_count;
    delete data.screens_count;

    if (!data.payment_screens.length) delete data.payment_screens;
    if (!data.onboarding_screens.length) delete data.onboarding_screens;

    return data.payment_screens ? createEditPaymentFlowStructure(data) : data;
  }
}

export default new FlowsNormalizer();
