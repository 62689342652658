import React from 'react';

import ScreensNormalizer from 'normalizer/ScreensNormalizer';
import ScreensForm from 'forms/Screens';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import CreateFromModal from "forms/CreateFromModal";

const ScreensAdd = (props: ControllerPropsInterface) => (
  <CreateFromModal {...props}>
    <ScreensForm
     actionType={ActionType.ADD}
     onSuccess={defaultOnSuccess}
    transform={ScreensNormalizer.create}
   {...props}
  />
 </CreateFromModal>
);

export default ScreensAdd;
