import React from 'react';

import FlowsNormalizer from 'normalizer/FlowsNormalizer';
import FlowsForm from 'forms/FlowsForm';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import CreateFromModal from "forms/CreateFromModal";

const FlowsAdd = (props: ControllerPropsInterface) => (
 <CreateFromModal {...props}>
  <FlowsForm
    actionType={ActionType.ADD}
    onSuccess={defaultOnSuccess}
    transform={FlowsNormalizer.create}
   {...props}
  />
 </CreateFromModal>
);

export default FlowsAdd;
