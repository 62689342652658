import React from 'react';
import { useHistory } from 'react-router';
import {
 Create,
 SimpleForm,
 SelectInput,
 required,
 ReduxState,
} from 'react-admin';

import { getChoicesList } from 'utils/common';
import Modal from 'components/Modal';
import CustomToolbar from '../components/CustomToolbar';

import './CreateScreenModal.scss';
import {useSelector} from "react-redux";

const CreateScreenModal = ({ isOpen, onCloseModal, modalTitle, serverConstants, ...rest }: any) => {
 const { projectsList } = serverConstants;

 const history = useHistory();

 const handleSubmit = (data: any) => {
  history.push({
   pathname: '/screens/create',
     state: data,
   });
  };

  return (
   <Modal
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      modalTitle={modalTitle}
    >
      <Create {...rest}>
       <SimpleForm
          toolbar={CustomToolbar()}
          save={handleSubmit}
          onSubmit={() => {}}
     >
          <SelectInput
            source="project_id"
            choices={projectsList}
            initialValue={useSelector((state: ReduxState) => state.project)}
            validate={[required('Project field is required')]}
           resettable
      />
          <SelectInput
            source="type"
            initialValue={useSelector((state: ReduxState) => state.screenType)}
            choices={getChoicesList(Object.values(serverConstants?.screen_type?.items))}
            validate={[required('Type field is required')]}
           resettable
       />
        </SimpleForm>
    </Create>
    </Modal>
  );
};

export default CreateScreenModal;
