import React, {useState} from "react";
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
// @ts-ignore
import {getItemById} from "utils/common";
import {withServerConstants} from "providers/ServerConstantsProvider";
import {PAYMENT} from "constants/screens";

import "./ScreensForm.scss";
import {ActionType, FormPropsInterface} from "interfaces/CommonInterface";

import {If} from "typings/tsx-control-statements.d";
import SimpleFormCustom from "../SimpleFormCustom";
import {ScreenProductsForm} from "./ScreenProductsForm";
import {validateCyrillic} from "../../validators/Experiments/ExperimentsValidator";
import {isJsonString} from "./screensUtils";
import SchemaForm from "./SchemaForm";
import EditDeleteToolbar from "../../components/EditDeleteToolbar";

const ScreensForm = (props: FormPropsInterface) => {
  const {
    location: {state},
    actionType,
    record,
    serverConstants,
  } = props;
  const projectsList = serverConstants?.projectsList;
  const projectId = state && state.project_id ? state.project_id : record.project_id;
  const configConverter = (e: object) => JSON.stringify(e, null, 2);
  const [isUpdate, setUpdate] = useState(true);
  const [isCopy, setCopy] = useState(false);

  if (actionType === ActionType.ADD) {
    if (record?.created_at) {
      setCopy(true)
    }
    if (!record.config_schema && record.config) {
      delete record.config;
      setCopy(false)
    }
    const formFields = [
      "updated_at",
      "experiments_count",
      "created_at",
      "created_by",
      "last_updated_by",
    ];
    Object.keys(record)
      .filter((x) => formFields.includes(x))
      .forEach((e) => delete record[e]);
    if (!!record.products && !record.products.length) {
      delete record.products;
    }
  }
  if (record?.config) {
    if (typeof record.config !== "string") {
      record.config = configConverter(record?.config)
    }
    const test = JSON.parse(record?.config)
    if (Array.isArray(test)) {
      record.config = configConverter(
        Object.assign({}, ...test.map((item: any) => ({[item.key]: item.value}))));
    }
  }

  const changeRecordConfig = (data: any) => {
    props.record.config = configConverter(data)
    setUpdate(false);
  };
  const copySchema = isCopy && record?.config_schema
  const hideSchema = isCopy && !record?.config && !record?.config_schema

  return (
    <SimpleFormCustom
      isLoaded={!!projectsList}
      {...props}
      toolbar={
        actionType === ActionType.EDIT ? (
          <EditDeleteToolbar
            alwaysEnableSaveButton={isUpdate}
            record={record}
          />
        ) : undefined
      }
    >
      <TextInput
        source="name"
        validate={[required("Name field is required"), validateCyrillic]}
        parse={(value: string) => value.trim().replace(/ /g, "_")}
      />
      <TextInput
        source="client_id"
        label="ID (Client)"
        validate={[required("ID field is required"), validateCyrillic]}
        parse={(value: string) => value.trim().replace(/ /g, "_")}
      />
      <TextInput
        source="project_id"
        initialValue={actionType === ActionType.ADD && state?.project_id}
        format={(value: number) => getItemById(projectsList, value)?.name}
        validate={[required("Project field is required"), validateCyrillic]}
        disabled
      />
      <TextInput
        source="type"
        initialValue={actionType === ActionType.ADD && state?.type}
        validate={[required("Processor field is required"), validateCyrillic]}
        disabled
        parse={(value: string) => value.trim().replace(/ /g, "_")}
      />
      <TextInput
        multiline
        rows={5}
        source="description"
        validate={[validateCyrillic]}
      />
      <If condition={actionType === ActionType.ADD && !copySchema && !hideSchema || copySchema && !hideSchema }>
        <TextInput
          multiline
          rows={5}
          label="schema"
          disabled={copySchema || false}
          source="config_schema"
          validate={[isJsonString]}
        />
      </If>
      <If condition={record?.config}>
        <TextInput multiline rows={5} source="config" disabled/>
      </If>
      <If condition={actionType === ActionType.EDIT && record?.config_schema || copySchema}>
        <SchemaForm
          record={record}
          onConfirm={(data: any) => changeRecordConfig(data)}
        />
      </If>
      <If condition={record?.type === PAYMENT || state?.type === PAYMENT}>
        <ScreenProductsForm
          serverConstants={serverConstants}
          projectId={projectId}
        />
      </If>
    </SimpleFormCustom>
  );
};

export default withServerConstants(ScreensForm);
