import React from 'react';
import { useHistory } from 'react-router';
import {
 Create,
 SimpleForm,
 SelectInput,
 required,
 ReduxState,
} from 'react-admin';

import Modal from 'components/Modal';
import CustomToolbar from '../components/CustomToolbar';

import './CreateExperimentModal.scss';
import { getItemById } from 'utils/common';
import {useSelector} from "react-redux";

const CreateExperimentModal = ({isOpen, onCloseModal, modalTitle, serverConstants, ...rest}: any) => {
 const {branchesList, projectsList} = serverConstants;
 const history = useHistory();
 const sortedBranchList = branchesList.sort((a: any, b: any) => (a.project_id > b.project_id && a.name > b.name) ? 1 : -1)

 const normalizedBranchesList = sortedBranchList.map((value: any) => {
  const projectName = getItemById(projectsList, value.project_id)?.name;
  return {...value, name: `${projectName || 'N/A'} | ${value.name}`};
 });


 const handleSubmit = (data: any) => {
  history.push({
   pathname: '/experiments/create',
   state: data,
  });
 };

 return (
  <Modal
   isOpen={isOpen}
   onCloseModal={onCloseModal}
   modalTitle={modalTitle}
  >
   <Create {...rest}>
    <SimpleForm
     toolbar={CustomToolbar()}
     save={handleSubmit}
     onSubmit={() => {}}
    >
     <SelectInput
      source="branch_id"
      choices={normalizedBranchesList}
      initialValue={useSelector((state: ReduxState) => state.branch)}
      validate={[required('Branch field is required')]}
      resettable
     />
    </SimpleForm>
   </Create>
  </Modal>
 );
};

export default CreateExperimentModal;
