import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import {
 Create,
 SimpleForm,
 SelectInput,
 required, ReduxState,
} from 'react-admin';

import Modal from 'components/Modal';
import CustomToolbar from '../components/CustomToolbar';

import './CreateFlowsModal.scss';
import {useSelector} from "react-redux";

const CreateFlowsModal = ({ isOpen, onCloseModal, modalTitle, serverConstants, ...rest }: any) => {
 const { projectsList } = serverConstants;

 const history = useHistory();

 const handleSubmit = (data: any) => {
  history.push({
   pathname: '/flows/create',
   state: data,
  });
 };

 return (
  <Modal
   isOpen={isOpen}
   onCloseModal={onCloseModal}
   modalTitle={modalTitle}
  >
   <Create {...rest}>
    <SimpleForm
     toolbar={CustomToolbar()}
     save={handleSubmit}
     onSubmit={() => {}}
    >
     <SelectInput
      source="project_id"
      choices={projectsList}
      initialValue={useSelector((state: ReduxState) => state.project)}
      validate={[required('Project field is required')]}
      resettable
     />
     <SelectInput
      source="type"
      choices={[
       { id: 'onboarding', name: 'Onboarding' },
       { id: 'payment', name: 'Payment' },
      ]}
      initialValue={useSelector((state: ReduxState) => state.flowType)}
      validate={[required('Type field is required')]}
      resettable
     />
    </SimpleForm>
   </Create>
  </Modal>
 );
};

CreateFlowsModal.propTypes = {
 isOpen: PropTypes.bool.isRequired,
 onCloseModal: PropTypes.func.isRequired,
 modalTitle: PropTypes.string.isRequired,
 serverConstants: PropTypes.object.isRequired,
};

export default CreateFlowsModal;
