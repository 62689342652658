import React from 'react';
import { ServerConstantsConsumer } from './ServerConstantsProvider';

const withServerConstants = (Component) => {
 const WrappedComponent = (props) => (
  <ServerConstantsConsumer>
   {(serverConstants) => <Component {...props} serverConstants={serverConstants} />}
  </ServerConstantsConsumer>
 );

 WrappedComponent.displayName = `withServerConstants(${Component.displayName})`;

 return WrappedComponent;
};

export default withServerConstants;
