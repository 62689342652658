import React from 'react';
import {required, SelectInput, SortPayload, TextInput, useGetList,} from 'react-admin';

import {If} from 'typings/tsx-control-statements.d';

import {getItemById} from 'utils/common';
import {withServerConstants} from 'providers/ServerConstantsProvider';

import './BranchesForm.scss';
import SimpleFormCustom from '../SimpleFormCustom';
import {ONBOARDING, PAYMENT, START_PAGE} from 'constants/screens';
import {getResponseList} from '../../utils/dataProvider';
import {FlowsResponseInterface} from "../../interfaces/Flows/FlowsResponseInterface";
import {ActionType, FormPropsInterface} from "../../interfaces/CommonInterface";
import EditToolbar from "../../components/EditToolbar";
import {validateCyrillic} from "../../validators/Experiments/ExperimentsValidator";

const BranchesForm = (props: FormPropsInterface) => {
 const {
  serverConstants,
  actionType,
  location: {
   state,
  },
 } = props;

 const projectId = actionType === ActionType.ADD ? state?.project_id : props?.record?.project_id;
 const startScreenResponse = useGetList(
   'screens',
   {page: 1, perPage: 9999},
   {} as SortPayload,
   {'project_id': projectId, 'type': START_PAGE}
 )
 const flowResponse = useGetList(
   'flows',
   {page: 1, perPage: 9999},
   {} as SortPayload,
   {'project_id': projectId}
 )
 const startScreenList = getResponseList(startScreenResponse);
 const flowsList: FlowsResponseInterface[] = getResponseList(flowResponse);

 const onboardingFlowList = flowsList.filter((item) => item.type === ONBOARDING);
 const paymentFlowList = flowsList.filter((item) => item.type === PAYMENT);

 return (
  <SimpleFormCustom
   isLoaded={serverConstants?.projectsList && startScreenResponse.loaded && flowResponse.loaded}
   toolbar={ <EditToolbar /> }
   {...props}
  >
   <TextInput
    source="name"
    validate={[required('Name field is required'), validateCyrillic]}
    parse={(value: string) => value.trim().replace(/ /g,"_")}
   />
   <TextInput
    source="url_segment"
    label="URL part"
    validate={[required('Url field is required'), validateCyrillic]}
    disabled={actionType === 'edit'}
    parse={(value: string) => value.trim().replace(/ /g,"_")}
   />
   <TextInput
    source="project_id"
    initialValue={actionType === ActionType.ADD && state?.project_id}
    format={(id: number) => getItemById(serverConstants.projectsList, id)?.name}
    disabled
   />
   <TextInput
    multiline
    rows={5}
    source="description"
    validate={[validateCyrillic]}

   />
   <div>
    <div className="BranchesForm__variant-title">Default variant</div>
    <div className="BranchesForm__variant-wrapper">
     <SelectInput
      key="first_page_id"
      source="first_page_id"
      choices={startScreenList}
      resettable
      className="BranchesForm__input"
     />
     <SelectInput
      key="onboarding_flow_id"
      source="onboarding_flow_id"
      choices={onboardingFlowList}
      resettable
      className="BranchesForm__input"
     />
     <SelectInput
      key="payment_flow_id"
      source="payment_flow_id"
      choices={paymentFlowList}
      resettable
      className="BranchesForm__input"
     />
    </div>
   </div>
  </SimpleFormCustom>
 );
};

export default withServerConstants(BranchesForm);
