import React from 'react';
import {
 Edit,
} from 'react-admin';

import ProductCodesForm from 'forms/ProductCodes/ProductCodesForm';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import ProductCodesNormalizer from "../../normalizer/ProductCodesNormalizer";

const ProductCodesEdit = (props: ControllerPropsInterface) => (
 <Edit {...props}>
  <ProductCodesForm
    actionType={ActionType.EDIT}
    onSuccess={defaultOnSuccess}
    redirect="list"
    transform={ProductCodesNormalizer.update}
   {...props}
  />
 </Edit>
);

export default ProductCodesEdit;
