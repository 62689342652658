import React from 'react';
import { Create } from 'react-admin';

import { withServerConstants } from 'providers/ServerConstantsProvider';
import ProjectsNormalizer from 'normalizer/ProjectsNormalizer';
import ProjectsForm from 'forms/Projects/ProjectsForm';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';
import { ServerConstantsOnSuccess } from "../CommonController";

const ProjectsAdd = (props: ControllerPropsInterface) => (
 <Create {...props}>
  <ProjectsForm
   actionType={ActionType.ADD}
   onSuccess={ServerConstantsOnSuccess}
   transform={ProjectsNormalizer.create}
   {...props}
  />
 </Create>
);

export default withServerConstants(ProjectsAdd);
