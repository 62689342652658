export const previousNameValidator = (previousName: string) => (value: string) => (
    previousName === value ? 'Name must be not equal with previous.' : undefined
);

export const validateTraffic = (value: any, values: any) => {
    let trafficResult = 0;
    values.variants.forEach((item: any) => trafficResult += item?.traffic_percent || 0);

    return trafficResult < 100 || trafficResult > 100 ? 'Sum of values must be 100%' : undefined
};

export const validateVariantName = (value: any, values: any) => {
    let variantNames: string[] = [];
    values.variants.forEach((item: any) => variantNames.push(item?.name || ''));

    return (new Set(variantNames)).size !== variantNames.length ? 'Name must be unique' : undefined
};

export const validatePage = (value: any, values: any, props: any) => {
    let variantNames: string[] = [];

    values.variants.forEach((item: any) => variantNames.push(
        item?.first_page_id ||
        item?.onboarding_flow_id ||
        item?.payment_flow_id ||
        ''));

    return variantNames[+props.name.match(/\d+/g)] === '' ? 'Must have at least one value' : undefined
};

export const validateProductCode = (value: any) => /^[a-z0-9_.-]*$/.test(value) ? undefined : 'Uppercase or cyrillic forbidden'
export const validateCyrillic = (value: any) => /[а-щА-ЩЬьЮюЯяЇїІіэЭЫыЪъёЁЄєҐґ]/.test(value) ? 'Cyrillic is forbidden' : undefined

export const isNumber = (value: any) => value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined;
export const cleanEmptyParams = (params: object) => Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, value !== '' ? value : null])
)