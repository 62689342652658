import {ArrayInput, FormDataConsumer, required, SelectInput, SimpleFormIterator} from 'react-admin';
import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as IconLink} from './img/icon-link.svg';
import {ActionType} from "interfaces/CommonInterface";

const setScreensChoices = (list: any[], screens: any[], currentId: number) => (
  list.filter(({ id: itemId }) => (
    !screens.find((screen) => screen && screen.screen_id === itemId && screen.screen_id !== currentId)
  ))
);

export const OnboardingFlowsForm = ({ screensList }: { screensList: any[] }) => (
  <ArrayInput
   source="onboarding_screens"
   validate={[required('Screens field is required')]}
  >
   <SimpleFormIterator>
    <FormDataConsumer>
     {({formData, scopedFormData, getSource}) => (
      <div className="FlowsForm__screens">
       <SelectInput
        key={scopedFormData?.screen_id}
        label="Screen"
        source={getSource ? getSource('screen_id'): 'default'}
        choices={setScreensChoices(
         screensList,
         formData?.onboarding_screens,
         scopedFormData?.screen_id
        )}
        className="FlowsForm__select-screen"
        validate={[required('Screen field is required')]}
       />
       <Link
        to={`/screens/${scopedFormData?.screen_id}`}
        target="_blank"
        className="FlowsForm__icon-link"
       >
        <IconLink/>
       </Link>
      </div>
     )}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
);
