// @ts-ignore
import isUndefined from 'lodash/isUndefined';
import {ProductStateType} from "interfaces/CommonInterface";
import {FormApi} from "final-form";
import {getResponseList} from "utils/dataProvider";


export const getProductPricePerState = (productPrice: number, product_state: ProductStateType, period: number) => {
    let productPricePerState = productPrice;

    if (product_state) {
        // Get price per one day
        productPricePerState /= period;

        if (product_state === ProductStateType.PER_WEEK) {
            productPricePerState *= 7;
        } else if (product_state === ProductStateType.PER_MONTH) {
            productPricePerState *= 31;
        }
    }

    return Math.trunc(productPricePerState);
};

export const calculateDiscountEstimated = (form: FormApi, productsResponse: any) => (e: any) => {
    const index = e.target.name.replace(/[^\d]/g, '');
    const { products, product_state } = form.getState().values;
    const productId = products[index]?.product_id;
    const product = productsResponse.data[productId];
    processPerState(form,product,product_state,index)
};

export const setProductsChoices = (productsResponse: any, products: any[], currentId: number) => (
    getResponseList(productsResponse).filter(({id: itemId}) => (
        !products.find((product) => product && product.product_id === itemId && product.product_id !== currentId)
    ))
);


export const isJsonString = (str: string) => {
    if (str !== undefined) {
        try {
            JSON.parse(str);
        } catch (e) {
            return "JSON is not valid";
        }
    }

    return undefined;
}

export const processPerState = (form: FormApi, product: any, product_state: ProductStateType, productIndex: number) => {
    const formValues = form.getState().values

    form.change(`products.[${productIndex}].price`, product.price);
    form.change(`products.[${productIndex}].processor`, product.processor);
    form.change(`products.[${productIndex}].prices`, product.prices);

    // if (!product.prices) {
    //     const pricePerState = getProductPricePerState(product.price, product_state, product.period);
    //     const startPricePerState = getProductPricePerState(product.start_price, product_state, product.period);
    //
    //     form.change(`products.[${productIndex}].price_per_state`, pricePerState);
    //     form.change(`products.[${productIndex}].start_price_per_state`, startPricePerState || '');
    // }


    product.prices.forEach((price: any, product_price_index: number) => {
        const startPrice =  formValues?.products?.[productIndex]?.prices?.[product_price_index]?.start_price ??  price.start_price
        const pricePerState = formValues?.products?.[productIndex]?.prices?.[product_price_index]?.price_per_state ??  price.price_per_state
        const startPricePerState = formValues?.products?.[productIndex]?.prices?.[product_price_index]?.start_price_per_state ??  price.start_price_per_state

        form.change(`products.[${productIndex}].prices[${product_price_index}].start_price`, startPrice);
        form.change(`products.[${productIndex}].prices[${product_price_index}].price_per_state`, pricePerState);
        form.change(`products.[${productIndex}].prices[${product_price_index}].start_price_per_state`, startPricePerState);
        form.change(`products.[${productIndex}].prices[${product_price_index}].is_default`, price.is_default);
    });
};