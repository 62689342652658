import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Confirm } from "react-admin";
import { IChangeEvent } from "@rjsf/core";
import Form from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { Loyalty } from "@material-ui/icons";
import { SchemaFormProps } from "../../interfaces/CommonInterface";

import "./SchemaForm.scss";

const SchemaForm = (props: SchemaFormProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  let formData = JSON.parse(props.record.config) || {};
  const handleChange = (e: IChangeEvent) => {
    if (e.formData && Object.keys(e.formData).length !== 0) {
      formData = e.formData;
    }
  };

  const handleConfirm = () => {
    props.onConfirm(formData);
    handleDialogClose();
  };

  // @ts-ignore
  return (
    <Button color="secondary" onClick={handleClick} startIcon={<Loyalty />}>
      Add configs
      <Confirm
        isOpen={open}
        title={`Add config for screen ${props.record.name}`}
        content={
          <Form
            className="schema-form"
            schema={JSON.parse(props.record.config_schema)}
            validator={validator}
            onChange={handleChange}
            formData={JSON.parse(props.record.config)}
          ><></>
          </Form>
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Button>
  );
};

export default SchemaForm;
