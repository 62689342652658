import React from 'react';
import { Edit } from 'react-admin';

import ProjectsNormalizer from 'normalizer/ProjectsNormalizer';
import ProjectsForm from 'forms/Projects/ProjectsForm';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';
import { ServerConstantsOnSuccess } from '../CommonController';

const ProjectsEdit = (props: ControllerPropsInterface) => (
 <Edit {...props}>
  <ProjectsForm
   actionType={ActionType.EDIT}
   onSuccess={ServerConstantsOnSuccess}
   redirect="list"
   transform={ProjectsNormalizer.update}
   {...props}
  />
 </Edit>
);

export default ProjectsEdit;
