import React from 'react';
import {Filter, TextInput} from 'react-admin';
import {renderFilterSelectInput, setProjectFromStore} from '../FiltersUtils';
import { getChoicesList } from 'utils/common';
import {SET_PROJECT} from "redux/types";

const ProductCodesFilters = (props: any) => {
 const { serverConstants, ...rest } = props;
 setProjectFromStore(props);


 return (
  <Filter {...rest}>
   {renderFilterSelectInput('project_id', serverConstants?.projectsList, SET_PROJECT)}
   <TextInput source="name" resettable alwaysOn parse={(value: string) => value.trim()}/>
  </Filter>
 );
};

export default ProductCodesFilters;
