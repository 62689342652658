import React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {ModalInterface} from "../../interfaces/Components/ModalInterface";

const styles = (theme: Theme) => ({
 root: {
  margin: 0,
  padding: theme.spacing(2),
 },
 closeButton: {
  position: 'absolute' as 'absolute', // It's issue in TS and material ui
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
 },
});

const DialogTitle = withStyles(styles)((props: any) => {
 const { children, classes, onClose, id } = props;
 return (
  <MuiDialogTitle disableTypography className={classes?.root} id={id}>
   <Typography variant="h6">{children}</Typography>
   {onClose ? (
    <IconButton aria-label="close" className={classes?.closeButton} onClick={onClose}>
     <CloseIcon />
    </IconButton>
   ) : null}
  </MuiDialogTitle>
 );
});

const DialogContent = withStyles((theme) => ({
 root: {
  padding: theme.spacing(2),
 },
}))(MuiDialogContent);

const Modal = ({children, isOpen, modalTitle, onCloseModal}: ModalInterface) => (
  <Dialog onClose={onCloseModal} aria-labelledby="customized-dialog-title" open={isOpen}>
   <DialogTitle id="customized-dialog-title" onClose={onCloseModal}>
    {modalTitle}
   </DialogTitle>
   <DialogContent dividers>
    {children}
   </DialogContent>
  </Dialog>
);

export default Modal;
