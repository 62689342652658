import AbstractNormalizer from './AbstractNormalizer';

class CurrenciesNormalizer extends AbstractNormalizer {
  create(payload: any) {
    return payload;
  }

  update(payload: any) {
    return super.beforeUpdate(payload);
  }
}

export default new CurrenciesNormalizer();
