import React, { useState } from 'react';
import {
 FunctionField,
 TextField,
 EditButton, DeleteWithConfirmButton, useGetList,
} from 'react-admin';

import {
  If,
  Choose,
  When,
  Otherwise
} from 'typings/tsx-control-statements.d';

import { getDefaultRowStyle, getItemById } from 'utils/common';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import List from 'components/List';
import Loader from 'components/Loader';
import CreateBranchesModal from 'modals/CreateBranchesModal';
import BranchesFilters from 'filters/Branches/BranchesFilters';
import BranchesPanel from 'panels/Branches/BranchesPanel';
import { Record } from 'ra-core';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import CreateWithModalAction from 'actions/CreateWithModalAction';
import {isNotViewer} from "../../utils/authProviderProvider";

const Branches = (props: ControllerPropsInterface) => {
  const { serverConstants, permissions, ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);
  const screens = useGetList('screens', {page: 1, perPage: 9999});
  const flows = useGetList('flows', {page: 1, perPage: 9999});

  return (
   <Choose>
      <When condition={permissions && !!serverConstants?.projectsList}>
        <List
          actions={isNotViewer(props.permissions) ? (
         <CreateWithModalAction
              openModal={() => setIsOpen(true)}
         />
          ) : false}
          rowStyle={(record: Record) => getDefaultRowStyle(serverConstants, record.project_id)}
          filters={<BranchesFilters serverConstants={serverConstants} />}
          expand={ ({ record }) => (
              <BranchesPanel
                record={record}
                screensList={screens.data}
                flowsList={flows.data}
              />
            )}
          // expand={<BranchesPanel />}
          sort={{ field: 'created_at', order: 'DESC' }}
          {...rest}
        >
          <FunctionField
          source="project_id"
       render={(record: Record | undefined) => getItemById(serverConstants.projectsList, record?.project_id)?.name}
          />
         <TextField source="name" />
      <TextField
            source="url_segment"
            label="Url part"
          />
      <If condition={isNotViewer(props.permissions)}>
       <EditButton />
       </If>
        </List>
        <If condition={isNotViewer(props.permissions)}>
          <CreateBranchesModal
         modalTitle="Create Branch"
            isOpen={isOpen}
            onCloseModal={() => setIsOpen(false)}
         serverConstants={serverConstants}
            {...rest}
          />
        </If>
    </When>
      <Otherwise>
      <Loader />
      </Otherwise>
    </Choose>
  );
};

export default withServerConstants(Branches);
