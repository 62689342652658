import AbstractNormalizer from './AbstractNormalizer';

class BranchesNormalizer extends AbstractNormalizer {
  create(payload: any) {
    return payload;
  }

  update(payload: any) {
    const data = super.beforeUpdate(payload);
    delete data.project_id;
    delete data.url_segment;
    delete data.request_counter;
    return data;
  }
}

export default new BranchesNormalizer();
