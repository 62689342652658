import React from 'react';

import ProductsNormalizer from 'normalizer/ProductsNormalizer';
import ProductsForm from 'forms/Products/ProductsForm';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import CreateFromModal from "forms/CreateFromModal";

const ProductsAdd = (props: ControllerPropsInterface) => (
 <CreateFromModal {...props}>
  <ProductsForm
    actionType={ActionType.ADD}
    onSuccess={defaultOnSuccess}
    transform={ProductsNormalizer.create}
    {...props}
  />
 </CreateFromModal>
);

export default ProductsAdd;
