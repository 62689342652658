import React, {useEffect, useState} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {Grid} from '@material-ui/core';

import {Column, Table} from '../../components/Table';
import './FlowsPanel.scss';

import {
If,
Choose,
When, Otherwise,
} from 'typings/tsx-control-statements.d';
import {FlowsPanelInterface} from "../../interfaces/Flows/FlowsPanelInterface";
import {FlowType} from "../../interfaces/CommonInterface";
import dataProvider from "../../providers/DataProvider";
import Loader from "../../components/Loader";

const getProducts = (screen: any, productsList: any) => (
  <List>
    {screen.products.map((value: any, index: number) => (
        // @ts-ignore
      <ListItem key={index}>{index + 1}.  {findObjectById(productsList, value.product_id)?.name}</ListItem>
    ))}
  </List>
);


const findObjectById = <T extends { id: number }>(array: T[] | undefined, idToFind: number): T | null => {
  if (!Array.isArray(array)) {
    return null;
  }

  const foundObject = array.find(obj => obj.id === idToFind);
  return foundObject || null;
};


const FlowsPanel = ({record}: FlowsPanelInterface) => {
const isPayment = record?.type === FlowType.PAYMENT;
const items = isPayment ? record.payment_screens?.reverse() : record.onboarding_screens;
let index = 1;
const screenIds = [...record.payment_screens, ...record.onboarding_screens].map(item => item.screen_id)
const [screensList, setScreensList] = useState([]);
const [productsList, setProductsList] = useState([]);
const [isScreenLoaded, setIsScreenLoaded] = useState(true);
const [isProductLoaded, setIsProductLoaded] = useState(true);

useEffect(() => {
      const fetchProducts = async () => {
          console.log('fetchProducts')
        try {
           const products: any = await dataProvider.getCustomList('products', {pagination:{page: 1, perPage: 9999}});
           setProductsList(products.data.items);
           setIsProductLoaded(false)
        } catch (error) {
           console.log('Error while fetching the list of screens:', error);
        }
      };

      fetchProducts();
}, [])
useEffect(() => {
      const fetchData = async () => {
                    console.log('fetchData')

        try {
           const result: any = await dataProvider.getListByIds('screens-filtered', {ids: screenIds});
           setScreensList(result.data.items);
           setIsScreenLoaded(false)
        } catch (error) {
           console.log('Error while fetching the list of screens:', error);
        }
      };

      fetchData();
}, [])
return (
      <Choose>
        <When condition={!isScreenLoaded && !isProductLoaded}>
           <div className="FlowsPanel">
              <Grid item container xs={6} alignContent="flex-end">
                <Choose>
                   <When condition={!!screensList}>
                      <Table tableClassName="FlowsPanelTable" items={items}>
                        <Column
                           header="#"
                           render={() => index++}
                        />
                        <Column
                           header="Name"
                           render={({screen_id}) => {
                              const foundObject = findObjectById(screensList, screen_id);
                              return (foundObject as unknown as { name?: string })?.name;
                           }}
                        />
                        <Column
                           header="Client ID"
                           render={({screen_id}) => {
                              const foundObject = findObjectById(screensList, screen_id);
                              return (foundObject as unknown as { client_id?: string })?.client_id;
                           }}
                        />
                        <If condition={isPayment}>
                         <Column
                          header="Products"
                          render={({ screen_id }) => {
                            const foundObject = findObjectById(screensList, screen_id);
                            return  getProducts(foundObject, productsList)
                          }}
                         />
                        </If>
                      </Table>
                   </When>
                </Choose>
              </Grid>
              <Grid item container xs={6} alignContent="center">
                Description: {record.description || 'N/A'}
              </Grid>
           </div>
        </When>
        <Otherwise>
           <Loader/>
        </Otherwise>
      </Choose>

);
};

export default FlowsPanel;
