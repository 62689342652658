import React from 'react';
import { useHistory } from 'react-router';
import {
 Create,
 SimpleForm,
 SelectInput,
 required, ReduxState,
} from 'react-admin';

import Modal from 'components/Modal';
import CustomToolbar from '../components/CustomToolbar';
import {useSelector} from "react-redux";

const CreateProductCodesModal = ({ isOpen, onCloseModal, modalTitle, serverConstants, ...rest }: any) => {
 const { projectsList } = serverConstants;

 const history = useHistory();

 const handleSubmit = (data: any) => {
  history.push({
   pathname: '/product-codes/create',
   state: data,
  });
 };

 return (
  <Modal
   isOpen={isOpen}
   onCloseModal={onCloseModal}
   modalTitle={modalTitle}
  >
   <Create {...rest}>
    <SimpleForm
     toolbar={CustomToolbar()}
     save={handleSubmit}
     onSubmit={() => {}}
    >
     <SelectInput
      source="project_id"
      choices={projectsList}
      initialValue={useSelector((state: ReduxState) => state.project)}
      validate={[required('Project field is required')]}
      resettable
     />
    </SimpleForm>
   </Create>
  </Modal>
 );
};

export default CreateProductCodesModal;
