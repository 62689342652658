import AbstractNormalizer from './AbstractNormalizer';

class ProductsNormalizer extends AbstractNormalizer {
  create(payload: any) {
    const data = payload;
    data.price = Math.round(parseFloat(payload.price));
    return data;
  }

  update(payload: any) {
    const data = super.beforeUpdate(payload);
    data.price = Math.round(parseFloat(payload.price));
    delete data.project_id;
    return data;
  }
}

export default new ProductsNormalizer();
