import React from 'react';

import {Choose, Otherwise, When} from "../../typings/tsx-control-statements.d";
import {Column, Table} from 'components/Table';
import {SortPayload, useGetList} from "react-admin";
import {toFloat} from "../../utils/common";
import {Grid} from "@mui/material";

const ProductsPanel = ({record}: any) => {
  const currencyResponse = useGetList(
    'currencies',
    {page: 1, perPage: 9999},
    {} as SortPayload,
  );

  return (
    <Choose>
      <When condition={!!record?.prices?.length}>
        <Table items={record.prices}>
          <Column
            header="Price"
            render={({price}) => toFloat(price)}
          />
          <Column
            header="Start price"
            render={({start_price}) => toFloat(start_price)}
          />
          <Column
            header="Currency"
            render={({currency_id}) => currencyResponse.data[currency_id]?.name}
          />
          <Column
            header="Currency code"
            render={({currency_id}) => currencyResponse.data[currency_id]?.code}
          />
          <Column
            header="Sign"
            render={({currency_id}) => currencyResponse.data[currency_id]?.sign}
          />
          <Column
            header="Location"
            render={({location}) => location}
          />
        </Table>
      </When>
      <Otherwise>
        <Grid item xs={6} container alignContent="flex-end">
          Description: {record.description || 'N/A'}
        </Grid>
      </Otherwise>
    </Choose>
  );
}

export default ProductsPanel;
