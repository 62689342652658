import { API } from 'aws-amplify';
import { getResponseWithErrors } from "utils/dataProvider";
import { API_NAME, DEFAULT_MAX_VALUE } from "constants/dataProvider";
import {ALLOWED_RESOURCE} from "../utils/constants";

const dataProvider = {
 getList: async (resource: string, { pagination, filter, sort }: any) => {
     if ( !ALLOWED_RESOURCE.includes(resource) && Object.keys(filter).length === 0) {
      return {data: [{id: 0}], total: 0}
    }
   const { items, _meta } = await API.get(
     API_NAME,
     `/${API_NAME}/${resource}`, {queryStringParameters: {
       page: pagination.page,
       per_page: pagination.perPage || DEFAULT_MAX_VALUE,
       ...filter,
       ...sort
     }}
   );

   return { data: items, total: _meta.total_items };
 },
    getCustomList: async (resource: string, { pagination, filter, sort }: any) => getResponseWithErrors(
   API.get(
     API_NAME,
     `/${API_NAME}/${resource}`, {queryStringParameters: {
       page: pagination.page,
       per_page: pagination.perPage || DEFAULT_MAX_VALUE,
       ...filter,
       ...sort
     }})
 ),
     getListByIds: async (resource: string, { ids }: any) => getResponseWithErrors(
   API.post(
     API_NAME,
     `/${API_NAME}/${resource}`,
     { body: ids }
   )
 ),
 getOne: async (resource: string, { id }: any) => getResponseWithErrors(
   API.get(
     API_NAME,
     `/${API_NAME}/${resource}/${id}`,
     null
   )
 ),
 create: async (resource: string, params: any) => getResponseWithErrors(
   API.post(
     API_NAME,
     `/${API_NAME}/${resource}`,
     { body: params.data }
   )
 ),
 update: async (resource: string, params: any) => getResponseWithErrors(
   API.put(
     API_NAME,
     `/${API_NAME}/${resource}/${params.id}`,
     { body: params.data }
   ),
 ),
 delete: async (resource: string, params: any) => getResponseWithErrors(
   API.del(
     API_NAME,
     `/${API_NAME}/${resource}/${params.id}`,
     null
   ),
 ),
 splitExperimentTraffic: async (params: any) => getResponseWithErrors(
   API.put(
     API_NAME,
     `/${API_NAME}/experiments/${params.id}/split-traffic`,
     { body: { variants: params.variants }}
   ),
 ),
 synchronizeRebillRates: async (params: any) => getResponseWithErrors(
   API.post(
     API_NAME,
     `/${API_NAME}/rebill-rates/sync`,
     { body: params }
   ),
 ),
    synchronizeRebillRatesSegment: async () => getResponseWithErrors(
      API.get(
          API_NAME,
          `/${API_NAME}/rebill-rates-segment/sync`,
          null
      ),
  ),
     runExperimentPipline: async (project_id:number, params:any) => getResponseWithErrors(
      API.get(
          API_NAME,
          `/${API_NAME}/experiments/pipeline/${project_id}`,
          {queryStringParameters: {...params}}
      ),
  ),
};

export default dataProvider;
