import {SimpleFormProps} from "react-admin";

export type ProductType = "lifetime" | "subscription";

export enum FlowType {
  PAYMENT = "payment",
  ONBOARDING = "onboarding"
}
export enum ProductStateType {
  PER_DAY = "per_day",
  PER_WEEK = "per_week",
  PER_MONTH = "per_month"
}

export enum ScreenType {
  PAYMENT = "payment",
  ONBOARDING = "onboarding",
  START_PAGE = "start_page"
}

export enum ActionType {
  ADD = "add",
  EDIT = "edit"
}

export type DateTimeInterface = string;

export type EmailInterface = string;

export interface ServerConstantsInfoInterface {
  flow_type: FlowType,
  screen_type: ScreenType,
  product_type: ProductType,
  language: string[],
  product_state_type: {items: any},
}
// TODO: add types
export interface ServerConstants extends ServerConstantsInfoInterface {
  projectsList: object[] | null,
   productCodesList: object[] | null,
  branchesList: object[] | null,
  loadingProjects: () => void,
  loadingBranches: () => void,
}

export interface OpenModalInterface{
  openModal: () => void,
}

export interface FormPropsInterface extends SimpleFormProps{
  actionType: ActionType,
  transform: (values: any) => object,
  onSuccess: (props: any, redirect:any) => void,
}
export interface LazyFormPropsInterface extends FormPropsInterface{
  isLoaded: boolean
}

export interface SchemaFormProps{
  record: {
    config: string,
    name: string,
    config_schema: string,
  },
  onConfirm: (formData: any) => void
}
