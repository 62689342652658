/* eslint-disable */
import AbstractNormalizer from './AbstractNormalizer';

class ExperimentsNormalizer extends AbstractNormalizer {
 static actions = {
  create: 'create',
  update: 'update',
 };

 static requiredFields = [
  'name',
  'description',
  'variants',
  'branch_id',
 ];

 static requiredVariantsFields = [
  'name',
  'first_page_id',
  'onboarding_flow_id',
  'payment_flow_id',
  'traffic_percent',
 ];

 create(payload: any) {
  return ExperimentsNormalizer.normalize(
   payload,
   ExperimentsNormalizer.requiredFields,
  );
 }

 update(payload: any) {
  return ExperimentsNormalizer.normalize(
   payload,
   ExperimentsNormalizer.requiredFields.filter((field) => field !== 'branch_id'),
  );
 }

 static normalize(payload: any, allowedFields: any) {
  const normalized = { variants: [] };

  Object.keys(payload).forEach((key) => {
   if (allowedFields.includes(key)) {
    if (key === 'variants') {
     Object.values(payload[key]).forEach((variant) => {
      // @ts-ignore
      normalized.variants.push(ExperimentsNormalizer.normalizeVariant(variant));
     });
    } else {
     // @ts-ignore
     normalized[key] = payload[key];
    }
   }
  });

  return normalized;
 }

 static normalizeVariant(variant: object) {
  const normalized: object = {};

  Object.keys(variant).forEach((variantKey) => {
   if (ExperimentsNormalizer.requiredVariantsFields.includes(variantKey)) {
    // @ts-ignore
    normalized[variantKey] = variant[variantKey];
   }
  });

  return normalized;
 }
}

export default new ExperimentsNormalizer();
