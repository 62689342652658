import React from 'react';
import { Pagination as PaginationComponent, PaginationProps } from 'react-admin';
import {EmptyTable} from "../EmptyTable";

const Pagination = (props: PaginationProps) => <PaginationComponent
  rowsPerPageOptions={[20, 50, 100, 500]}
  limit={<EmptyTable />}
  {...props}
/>;

export default Pagination;
