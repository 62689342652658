import React from 'react';

import ProductCodesForm from 'forms/ProductCodes/ProductCodesForm';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import CreateFromModal from "forms/CreateFromModal";
import ProductCodesNormalizer from "../../normalizer/ProductCodesNormalizer";

const ProductCodesAdd = (props: ControllerPropsInterface) => (
 <CreateFromModal {...props}>
  <ProductCodesForm
    actionType={ActionType.ADD}
    onSuccess={defaultOnSuccess}
    transform={ProductCodesNormalizer.create}
    {...props}
  />
 </CreateFromModal>
);

export default ProductCodesAdd;
