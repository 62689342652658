import React, {useState} from 'react';
import {
  TextField,
  EditButton,
  FunctionField, DeleteWithConfirmButton, CloneButton,
} from 'react-admin';

import {
  If,
  Choose,
  When,
  Otherwise
} from 'typings/tsx-control-statements.d';

import {getDefaultRowStyle, getItemById} from 'utils/common';
import {withServerConstants} from 'providers/ServerConstantsProvider';
import List from 'components/List';
import Loader from 'components/Loader';
import CreateProductsModal from 'modals/CreateProductsModal';
import ProductsFilters from 'filters/Products/ProductsFilters';
import ProductsPanel from 'panels/Products/ProductsPanel';
import CreateWithModalAction from 'actions/CreateWithModalAction';
import {ControllerPropsInterface} from 'interfaces/ControllerPropsInterface';
import {Record} from 'ra-core';
import {isNotViewer} from "../../utils/authProviderProvider";

const Products = (props: ControllerPropsInterface) => {
  const {serverConstants, permissions, ...rest} = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Choose>
      <When condition={permissions && !!serverConstants?.projectsList}>
        <List
          actions={isNotViewer(permissions) ? (
            <CreateWithModalAction
              openModal={() => setIsOpen(true)}
            />
          ) : false}
          rowStyle={(record: Record) => getDefaultRowStyle(serverConstants, record.project_id)}
          exporter={false}
          filters={<ProductsFilters serverConstants={serverConstants}/>}
          expand={<ProductsPanel/>}
          {...rest}
          sort={{field: 'created_at', order: 'DESC'}}
        >
          <TextField
            source="uuid"
            label="UUID"
          />
          <FunctionField
            source="project_id"
            render={(record: Record | undefined) => getItemById(serverConstants.projectsList, record?.project_id)?.name}
          />
          <TextField
            source="name"
          />
          <TextField
            source="type"
          />

          <TextField
            source="period"
            label="Period(days)"
          />
          <FunctionField
            source="price"
            render={(record: Record | undefined) => `$${record?.price / 100}`}
          />
          <TextField
            source="trial"
            label="Trial(days)"
          />
          <TextField
            label="Processor"
            source="processor"
          />
          <If condition={isNotViewer(permissions)}>
            <EditButton/>
            <CloneButton label="Copy"/>
            <DeleteWithConfirmButton confirmTitle={'Delete product'}/>
          </If>
        </List>
        <If condition={isNotViewer(permissions)}>
          <CreateProductsModal
            modalTitle="Create Product"
            isOpen={isOpen}
            onCloseModal={() => setIsOpen(false)}
            serverConstants={serverConstants}
            {...rest}
          />
        </If>
      </When>
      <Otherwise>
        <Loader/>
      </Otherwise>
    </Choose>
  );
};

export default withServerConstants(Products);
