import React from 'react';
import {
 Toolbar,
 SaveButton,
 ToolbarProps, DeleteButton
} from 'react-admin';

const EditDeleteToolbar = (props: ToolbarProps) => {

    return(
        <Toolbar {...props} style={{ justifyContent: 'space-between'  }}>
            <SaveButton
                disabled={!props.alwaysEnableSaveButton? props.alwaysEnableSaveButton: props.pristine}
                style={{ marginLeft: '15px' }}
                record={props.record}
            />
            <DeleteButton
                style={{ marginRight: '15px' }}
            />
        </Toolbar>
    );
}

export default EditDeleteToolbar;
