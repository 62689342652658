import React from 'react';
import {EditButton, TextField, CreateButton} from 'react-admin';
import List from 'components/List';
import {isNotViewer} from "../../utils/authProviderProvider";
import {ControllerPropsInterface} from "../../interfaces/ControllerPropsInterface";
import Loader from "../../components/Loader";

import {
  If,
  Choose,
  When,
  Otherwise
} from 'typings/tsx-control-statements.d';
import {makeStyles} from "@material-ui/core/styles";


const Projects = (props: ControllerPropsInterface) => {
  const useStyles = makeStyles({button: { margin: '1em' }});
  const classes = useStyles();
  const rowStyle = (record: Record<any, any>) => ({
    borderLeftColor: record.color,
    borderLeftWidth: 10,
    borderLeftStyle: 'solid',
    });
  return (
  <Choose>
    <When condition={props.permissions}>
      <List
        rowStyle={(record) => rowStyle(record)}
        exporter={false}
        sort={{ field: 'created_at', order: 'DESC' }}
        {...props}
        actions={isNotViewer(props.permissions) ? ( <CreateButton className={classes.button}/>
     ) : false}
      >
        <TextField source="name" />
        <TextField source="system_name" />
        <TextField source="domain" />
        <If condition={isNotViewer(props.permissions)}>
          <EditButton />
        </If>
      </List>
    </When>
    <Otherwise>
      <Loader />
    </Otherwise>
  </Choose>
)};

export default Projects;
