import React from 'react';
import {
 Toolbar,
 SaveButton,
 ToolbarProps
} from 'react-admin';

const EditToolbar = (props: ToolbarProps) => (
 <Toolbar {...props}>
  <SaveButton
   disabled={props.pristine}
   style={{ marginLeft: '15px' }}
  />
 </Toolbar>
);

export default EditToolbar;
