import React, {useState} from 'react';
import {
  TextField,
  NumberField,
  DateField,
  FunctionField,
  useGetList
} from 'react-admin';
import {Record} from 'ra-core';

import {
  Choose,
  When,
  Otherwise
} from 'typings/tsx-control-statements.d';

import {getDefaultRowStyle} from 'utils/common';
import {withServerConstants} from 'providers/ServerConstantsProvider';
import List from 'components/List';
import Loader from 'components/Loader';
import RebillRatesFilters from '../../filters/RebillRates/RebillRatesFilters';
import RebillRatesActions from '../../actions/RebillRates/RebillRatesActions';
import SynchronizeRebillRatesModal from 'modals/SynchronizeRebillRatesModal/SynchronizeRebillRatesModal';
import {ControllerPropsInterface} from 'interfaces/ControllerPropsInterface';
import {getResponseList} from "../../utils/dataProvider";
import {isAdmin} from "../../utils/authProviderProvider";

const RebillRates = (props: ControllerPropsInterface) => {
  const {serverConstants, ...rest} = props;
  const [isOpen, setIsOpen] = useState(false);
  const productsResponse = useGetList('products', {page: 1, perPage: 9999});
  const projectsList = serverConstants?.projectsList;

  const productsList = getResponseList(productsResponse);
  return (
    <Choose>
      <When condition={!!projectsList && productsResponse.loaded}>
        <List
          actions={isAdmin(props.permissions) ?
            <RebillRatesActions openModal={() => setIsOpen(true)}/> : false}
          rowStyle={(record: Record) => getDefaultRowStyle(serverConstants, record.project_id)}
          exporter={false}
          filters={<RebillRatesFilters projectsList={projectsList} productsList={productsList}/>}
          {...rest}
        >
          <TextField source="project_name" label="Project"/>
          <TextField source="product_uuid" label="UUID"/>
          <FunctionField
            label="Is product created"
            render={
              (record: any) => record.product_id ? 'Yes' : 'No'
            }
          />
          <TextField source="country" label="Country cluster"/>
          <NumberField source="rebill_rate"/>
          <DateField source="updated_at" showTime/>
        </List>
        <SynchronizeRebillRatesModal
          modalTitle="Update coefficient"
          isOpen={isOpen}
          onCloseModal={() => setIsOpen(false)}
          projectsList={projectsList}
          productsList={productsList}
          {...rest}
        />
      </When>
      <Otherwise>
        <Loader/>
      </Otherwise>
    </Choose>
  );
};

export default withServerConstants(RebillRates);
