import React from 'react';
import { Button } from 'react-admin';
import { OpenModalInterface } from 'interfaces/CommonInterface';

const RebillRatesActions = ({ openModal } : OpenModalInterface) => (
 <div>
  <Button
   label="Update coefficient"
   onClick={(e) => {
    e.preventDefault();
    openModal();
   }}
  />
 </div>
);

export default RebillRatesActions;
