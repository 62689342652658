import React from 'react';
import {Admin, Resource} from 'react-admin';
import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import WorkIcon from '@material-ui/icons/Work';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import CompareIcon from '@material-ui/icons/Compare';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

// custom providers (connect with amplify-aws and react-admin)
import dataProvider from 'providers/DataProvider';
import authProvider from 'providers/AuthProvider';

// providers for getting common data
import ServerConstantsProvider from 'providers/ServerConstantsProvider';

// custom layout
import MainLayout from 'layouts/MainLayout';

// routes
import Projects, {ProjectsAdd, ProjectsEdit} from 'controllers/Projects';
import Products, {ProductsAdd, ProductsEdit} from 'controllers/Products';
import ProductCodes, {ProductCodesAdd, ProductCodesEdit} from 'controllers/ProductCodes';
import RebillRates from 'controllers/RebillRates';
import RebillRatesSegment from 'controllers/RebillRatesSegment';
import Currencies, {CurrenciesAdd, CurrenciesEdit} from 'controllers/Currencies';
import Screens, {ScreensAdd, ScreensEdit} from 'controllers/Screens';
import Branches, {BranchesAdd, BranchesEdit} from 'controllers/Branches';
import Flows, {FlowsAdd, FlowsEdit} from 'controllers/Flows';
import Experiments, {ExperimentsAdd, ExperimentsEdit} from 'controllers/Experiments';
import customRoutes from 'controllers/customRoutes';
import rootReducer from './redux/rootReducer';

const i18nProvider = polyglotI18nProvider(
  () => englishMessages,
  'en',
  {allowMissing: true},
);

const App = () => (
  <ServerConstantsProvider>
    <Admin
      layout={MainLayout}
      customReducers={rootReducer}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
    >
      <Resource
        name="experiments"
        options={{label: 'Experiments'}}
        list={Experiments}
        create={ExperimentsAdd}
        edit={ExperimentsEdit}
        icon={<CompareIcon/>}
      />
      <Resource
        name="flows"
        options={{label: 'Flow parts'}}
        list={Flows}
        create={FlowsAdd}
        edit={FlowsEdit}
        icon={<LinearScaleIcon/>}
      />
      <Resource
        name="screens"
        options={{label: 'Screens'}}
        list={Screens}
        create={ScreensAdd}
        edit={ScreensEdit}
        icon={<PhonelinkSetupIcon/>}
      />
      <Resource
        name="products"
        options={{label: 'Products'}}
        list={Products}
        create={ProductsAdd}
        edit={ProductsEdit}
        icon={<LocalOfferIcon/>}
      />
      <Resource

        name="product-codes"
        options={{label: 'Product Codes'}}
        list={ProductCodes}
        create={ProductCodesAdd}
        edit={ProductCodesEdit}
        icon={<OfflineBoltIcon/>}
      />
      <Resource name="branches"
                options={{label: 'Branches'}}
                list={Branches}
                create={BranchesAdd}
                edit={BranchesEdit}
                icon={<AccountTreeIcon/>}
      />
      <Resource
        name="projects"
        options={{label: 'Projects'}}
        list={Projects}
        create={ProjectsAdd}
        edit={ProjectsEdit}
        icon={<WorkIcon/>}
      />
      <Resource
        name="rebill-rates"
        options={{label: 'LTV coefficient'}}
        list={RebillRates}
        icon={<AccountBalanceWalletIcon/>}
      />
      <Resource
        name="rebill-rates-segment"
        options={{label: 'LTV segment'}}
        list={RebillRatesSegment}
        icon={<DynamicFeedIcon/>}
      />
      <Resource
        name="currencies"
        options={{label: 'Currencies'}}
        list={Currencies}
        create={CurrenciesAdd}
        edit={CurrenciesEdit}
        icon={<AttachMoneyIcon/>}
      />
            <Resource
        name="feature-sets"
      />
    </Admin>
  </ServerConstantsProvider>
);

export default App;

