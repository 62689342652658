import React, { useState } from 'react';
import {
 TextField,
 EditButton,
 FunctionField, DeleteWithConfirmButton, CloneButton,
} from 'react-admin';

import {
 If,
 Choose,
 When,
 Otherwise
} from 'typings/tsx-control-statements.d';

import { getDefaultRowStyle, getItemById } from 'utils/common';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import List from 'components/List';
import Loader from 'components/Loader';
import ProductCodesFilters from 'filters/ProductCodes/ProductCodesFilters';
import CreateWithModalAction from 'actions/CreateWithModalAction';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { Record } from 'ra-core';
import {isNotViewer} from "../../utils/authProviderProvider";
import ProductCodesPanel from "../../panels/ProductCodes/ProductCodesPanel";
import CreateProductCodesModal from "../../modals/CreateProductCodesModal";

const ProductCodes = (props: ControllerPropsInterface) => {
 const { serverConstants, permissions, ...rest } = props;
 const [isOpen, setIsOpen] = useState(false);

 return (
  <Choose>
   <When condition={permissions && !!serverConstants?.projectsList}>
    <List
     actions={isNotViewer(permissions) ? (
      <CreateWithModalAction
       openModal={() => setIsOpen(true)}
      />
     ) : false}
     rowStyle={(record: Record) => getDefaultRowStyle(serverConstants, record.project_id)}
     exporter={false}
     filters={<ProductCodesFilters serverConstants={serverConstants} />}
     expand={<ProductCodesPanel />}
     {...rest}
     sort={{ field: 'created_at', order: 'DESC' }}
    >
     <FunctionField
      source="project_id"
      render={(record: Record | undefined) => getItemById(serverConstants.projectsList, record?.project_id)?.name}
     />
     <TextField
      source="name"
     />
     <TextField
      source="created_by"
     />
     <TextField
      source="created_at"
     />
     <TextField
      source="last_updated_by"
     />
     <If condition={isNotViewer(permissions)}>
       <EditButton />
          <CloneButton label="Copy" />
            <DeleteWithConfirmButton confirmTitle={'Delete product code'}/>
          </If>
        </List>
    <If condition={isNotViewer(permissions)}>
          <CreateProductCodesModal
            modalTitle="Create Product code"
            isOpen={isOpen}
            onCloseModal={() => setIsOpen(false)}
            serverConstants={serverConstants}
            {...rest}
          />
        </If>
      </When>
     <Otherwise>
        <Loader />
   </Otherwise>
    </Choose>
  );
};

export default withServerConstants(ProductCodes);
