import React, {useState} from 'react';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer, FunctionField,
  NumberInput,
  required,
  SelectInput,
  SimpleFormIterator,
  SortPayload,
  TextInput,
  useGetList,
} from 'react-admin';
import {useForm} from 'react-final-form';
// @ts-ignore
import round from 'lodash/round';

import { toFloat, toInt} from 'utils/common';
import './ScreensForm.scss';
import {calculateDiscountEstimated, setProductsChoices,} from './screensUtils';
import ProductIsPreloadInput from './ProductIsPreloadInput';
import ProductStateInput from "./ProductStateInput";

import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';
import Loader from "../../components/Loader";
import {Record} from "ra-core";
import {FormApi} from "final-form";

export const ScreenProductsForm = ({serverConstants, projectId}: { serverConstants: any, projectId: number }) => {
  const productsResponse = useGetList(
    'products',
    {page: 1, perPage: 9999},
    {} as SortPayload,
    {'project_id': projectId}
  );
  const currencyResponse = useGetList(
    'currencies',
    {page: 1, perPage: 9999},
    {} as SortPayload,
  );
  const form = useForm();
const updateFormValue = (form: FormApi,path: string, e: any) => form.change(path,  parseFloat(e.target.value) *100)
  return (
    <Choose>
      <When condition={!!serverConstants?.projectsList && productsResponse.loaded}>
        <ProductStateInput
          serverConstants={serverConstants}
          productsResponse={productsResponse}
          form={form}
        />
        <BooleanInput source="skip_checkout"/>
        <ArrayInput source="products" validate={[required('Products field is required')]}>
          <SimpleFormIterator>
            <FormDataConsumer>
              {({formData, scopedFormData, getSource,}) => {
                const processor = productsResponse.data[scopedFormData?.product_id]?.processor;
                const productPrice = productsResponse.data[scopedFormData?.product_id]?.price;
                const prices = productsResponse.data[scopedFormData?.product_id]?.prices
                return (
                  <>
                    <SelectInput
                      key={scopedFormData?.product_id}
                      label="Product"
                      source={getSource ? getSource('product_id') : 'default'}
                      choices={setProductsChoices(productsResponse, formData?.products, scopedFormData?.product_id)}
                      className="ScreensForm__input"
                      style={{width: '50%'}}
                      validate={[required('Price field is required')]}
                      onChange={calculateDiscountEstimated(form, productsResponse)}
                      disabled={!!scopedFormData?.product_id}
                    />
                    <TextInput
                      source={getSource ? getSource('processor') : 'default'}
                      initialValue={processor}
                      label="Processor"
                      className="ScreensForm__input"
                      disabled
                    />
                    {prices?.length > 0 ?
                      <ArrayInput source={getSource ? getSource('prices') : prices}
                            label="Prices"
                            validate={[required('Prices field is required')]}>
                        <SimpleFormIterator disableAdd disableRemove disableReordering
                        >
                          <FunctionField
                            label=""
                            render={(record: Record | undefined, source: any) => {
                              const matches = source.match(/\[(\d+)\]/g)
                              const index = matches ? parseInt(matches[1].match(/\d+/)[0]) : 0
                              return (
                                <>
                                  <NumberInput
                                    source={`${source}.start_price`}
                                    label="Start Price"
                                    className="ScreensForm__input"
                                    parse={(value: number) => toInt(value)}
                                    format={(value: number) => toFloat(value)}
                                    validate={[required('Start Price field is required')]}
                                    disabled={!!prices[index]?.start_price}
                                    onChange={e => updateFormValue(form, `${source}.start_price`, e)}
                                    min={0.01}
                                    step={0.01}
                                  />
                                  <NumberInput
                                    source={`${source}.price`}
                                    label="Price"
                                    className="ScreensForm__input"
                                    parse={(value: number) => toInt(value)}
                                    format={(value: number) => toFloat(value)}
                                    disabled
                                  />
                                  <NumberInput
                                    source={`${source}.start_price_per_state`}
                                    label="Start Price per State"
                                    className="ScreensForm__long_input"
                                    validate={[required('Start Price per State field is required')]}
                                    parse={(value: number) => toInt(value)}
                                    format={(value: number) => toFloat(value)}
                                    min={0.01}
                                    step={0.01}
                                    onWheel={() => {
                                    }}
                                  />
                                  <NumberInput
                                    source={`${source}.price_per_state`}
                                    label="Price per State"
                                    className="ScreensForm__input"
                                    validate={[required('Price per State field is required')]}
                                    parse={(value: number) => toInt(value)}
                                    format={(value: number) => toFloat(value)}
                                    onWheel={() => {
                                    }}
                                    min={0}
                                    step={0.01}
                                  />
                                  <TextInput
                                    source={`${source}.code` || currencyResponse.data[prices[index]?.currency_id]?.code}
                                    key={currencyResponse.data[prices[index]?.currency_id]?.code}
                                    value={currencyResponse.data[prices[index]?.currency_id]?.code}
                                    initialValue={currencyResponse.data[prices[index]?.currency_id]?.code}
                                    label="Code"
                                    className="ScreensForm__input"
                                    disabled
                                  />
                                  <TextInput
                                    source={`${source}.sign` || currencyResponse.data[prices[index].currency_id]?.sign}
                                    key={currencyResponse.data[prices[index]?.currency_id]?.sign}
                                    value={currencyResponse.data[prices[index]?.currency_id]?.sign}
                                    initialValue={currencyResponse.data[prices[index]?.currency_id]?.sign}
                                    label="Sign"
                                    className="ScreensForm__input"
                                    disabled
                                  />
                                  <TextInput
                                    source={`${source}.location`}
                                    key={prices[index]?.location}
                                    label="Locations"
                                    className="ScreensForm__input"
                                    disabled
                                  />
                                </>
                              )
                            }}
                          />

                        </SimpleFormIterator>
                      </ArrayInput> : <>
                        <TextInput
                          source={getSource ? getSource('price') : 'default'}
                          label="Price"
                          className="ScreensForm__input"
                          initialValue={productPrice}
                          parse={(value: number) => toInt(value)}
                          format={(value: number) => toFloat(value)}
                          disabled
                        />
                        <NumberInput
                          source={getSource ? getSource('price_per_state') : 'default'}
                          label="Price per State"
                          className="ScreensForm__input"
                          validate={[required('Price per State field is required')]}
                          parse={(value: number) => toInt(value)}
                          format={(value: number) => toFloat(value)}
                          onWheel={() => {
                          }}
                          min={0}
                          step={0.01}
                        />
                        <NumberInput
                          source={getSource ? getSource('start_price') : 'default'}
                          label="Start Price"
                          className="ScreensForm__input"
                          validate={[required('Start Price field is required')]}
                          parse={(value: number) => toInt(value)}
                          format={(value: number) => toFloat(value)}
                          min={0.01}
                          step={0.01}
                          onWheel={() => {
                          }}
                        />
                        <NumberInput
                          source={getSource ? getSource('start_price_per_state') : 'default'}
                          label="Start Price per State"
                          className="ScreensForm__long_input"
                          validate={[required('Start Price per State field is required')]}
                          parse={(value: number) => toInt(value)}
                          format={(value: number) => toFloat(value)}
                          min={0.01}
                          step={0.01}
                          onWheel={() => {
                          }}
                        /></>}
                    <ProductIsPreloadInput getSource={getSource}/>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </When>
      <Otherwise>
        <Loader/>
      </Otherwise>
    </Choose>
  );
};
