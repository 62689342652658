import React from 'react';
import { useForm } from 'react-final-form';
import { BooleanInput } from 'react-admin';
import { FormApi } from "final-form";
import { ScreenProductInterface } from "interfaces/Screens/ScreenProductsInterface";

const onClick = (form: FormApi, e: any) => {
  if (!e.target.name) {
   return;
  }

  const targetIndex = e.target.name.replace(/[^\d]/g, '');
  const { products } = form.getState().values;
  products.forEach((product: ScreenProductInterface, index: number) => {
    form.change(`products.[${index}].is_preselected`, false);
  });
  form.change(`products.[${targetIndex}].is_preselected`, true);
};

const ProductIsPreloadInput = ({ getSource }: {getSource: any}) => {
 const form = useForm();

 return (
  <BooleanInput
   label="Preselected"
   initialValue={false}
   source={getSource('is_preselected')}
   onClick={(e) => onClick(form, e)}
  />
 );
};

export default ProductIsPreloadInput;
