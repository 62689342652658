import { ControllerPropsInterface } from "../interfaces/ControllerPropsInterface";

export const ServerConstantsOnSuccess = (props: ControllerPropsInterface, redirect: any) => {
 defaultOnSuccess(props, redirect);

 if (props.resource === 'projects') props.serverConstants?.loadingProjects();
 else if (props.resource === 'branches') props.serverConstants?.loadingBranches();
};

export const defaultOnSuccess = (props: ControllerPropsInterface, redirect: any) => {
 redirect(props.basePath || '/');
};
