import PropTypes from 'prop-types';

const Column = () => null;

Column.propTypes = {
 header: PropTypes.string,
 render: PropTypes.func.isRequired,
 getHeadCellClassName: PropTypes.func,
 getBodyCellClassName: PropTypes.func,
 tooltip: PropTypes.string
};

Column.defaultProps = {
 header: null,
 getHeadCellClassName: () => {},
 getBodyCellClassName: () => {},
};

export default Column;
