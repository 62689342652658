import {SimpleForm, useMutation, useNotify, useRedirect} from 'react-admin';
import React, { useCallback } from 'react';
import {ActionType, LazyFormPropsInterface} from '../interfaces/CommonInterface';
import Loader from "../components/Loader";
import {Choose, Otherwise, When} from 'typings/tsx-control-statements.d';

const SimpleFormCustom = (props: LazyFormPropsInterface) => {
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const notify = useNotify();
  const onSave = useCallback(
    async (values) => {
      try {
        const type = props.actionType === ActionType.ADD ? 'create' : 'update';
        const payload = props.actionType === ActionType.ADD
          ? { data: props.transform(values) }
          : { id: values.id, data: props.transform(values) };

        await mutate({
          type: type,
          resource: props.resource,
          payload: payload,
        }, { returnPromise: true });
        notify(props.actionType === ActionType.ADD ? 'Created' : 'Updated', 'success');
        props.onSuccess(props, redirect);
      } catch (error) {
        if (error.body?.errors) {
          return { ...error.body.errors };
        }
        notify(error, 'error');
      }
    },
    [mutate],
  );

  return (
   <Choose>
    <When condition={props.isLoaded}>
     <SimpleForm
       className={props.className}
       basePath={props.basePath}
       record={props.record}
       version={props.version}
       redirect={props.redirect}
       resource={props.resource}
       save={onSave}
       onSubmit={props.onSubmit}
       initialValues={props.initialValues}
       validate={props.validate}
       toolbar={props.toolbar}
     >
      { props.children }
     </SimpleForm>
    </When>
    <Otherwise>
     <Loader />
    </Otherwise>
   </Choose>
  );
};

export default SimpleFormCustom;
