import React, { useState } from 'react';
import {
 FunctionField,
 TextField,
 DateField,
 EditButton,
 useGetList,
 DeleteWithConfirmButton,
 CloneButton
} from 'react-admin';

import { getDefaultRowStyle, getItemById } from 'utils/common';
import { withServerConstants } from 'providers/ServerConstantsProvider';
import List from 'components/List';
import Loader from 'components/Loader';
import CreateFlowsModal from 'modals/CreateFlowsModal';
import CreateWithModalAction from 'actions/CreateWithModalAction';
import FlowsFilters from 'filters/Flows/FlowsFilters';
import FlowsPanel from 'panels/Flows/FlowsPanel';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';

import {
 If,
 Choose,
 When,
 Otherwise
} from 'typings/tsx-control-statements.d';
import { Record } from 'ra-core';
import {isNotViewer} from "../../utils/authProviderProvider";

const Flows = (props: ControllerPropsInterface) => {
 const { serverConstants, permissions, ...rest } = props;

 const [isOpen, setIsOpen] = useState(false);

 return (
  <Choose>
   <When condition={permissions && !!serverConstants?.projectsList}>
    <List
     actions={isNotViewer(permissions) ? (
      <CreateWithModalAction
       openModal={() => setIsOpen(true)}
      />
     ) : false}
     expand={({ record }) => (
      <FlowsPanel
       record={record}
      />
     )}
     rowStyle={(record: Record) => getDefaultRowStyle(serverConstants, record.project_id)}
     filters={<FlowsFilters serverConstants={serverConstants} />}
     {...rest}
     sort={{ field: 'created_at', order: 'DESC' }}
    >
     <FunctionField
      source="project_id"
      render={(record: Record | undefined) => getItemById(serverConstants.projectsList, record?.project_id)?.name}
     />
     <TextField source="name" />
     <TextField source="type" />
     <TextField source="screens_count" />
     <TextField source="experiments_count" />
     <DateField source="created_at" />
     <If condition={isNotViewer(permissions)}>
      <FunctionField
       render={(record: Record | undefined) => (
         record?.experiments_count === 0 && (
         <EditButton record={record} basePath="/flows" />
        )
       )}
      />
      <FunctionField
       render={(record: Record | undefined) => (
         record?.type === 'onboarding' && (
         <CloneButton label="Copy" record={record}/>
        )
       )}
      />
      <DeleteWithConfirmButton confirmTitle={'Delete flow'}/>
     </If>
    </List>
    <If condition={isNotViewer(permissions)}>
     <CreateFlowsModal
      modalTitle="Create Flow"
      isOpen={isOpen}
      onCloseModal={() => setIsOpen(false)}
      serverConstants={serverConstants}
      {...rest}
     />
    </If>
   </When>
   <Otherwise>
    <Loader />
   </Otherwise>
  </Choose>
 );
};

export default withServerConstants(Flows);
