import React from 'react';
import { Create } from 'react-admin';

import {useHistory} from "react-router";

const CreateFromModal = (props: any) => {
  const { children } = props;
  if (!props.location.state && !props.location.search) {
    const history = useHistory();
    history.push({ pathname: props.basePath, state: {isOpen: true}});
  }

  return (
    <Create {...props}>
      {children}
    </Create>
  );
};

export default CreateFromModal;
