import React from 'react';

import { Grid } from '@material-ui/core';

const ProductCodesPanel = ({ record }: any) => (
 <Grid item xs={6} container alignContent="flex-end">
  Description: {record.description || 'N/A'}
 </Grid>
);

export default ProductCodesPanel;
