import React from 'react';
import {Filter, TextInput} from 'react-admin';
import {renderFilterSelectInput, setProjectFromStore} from '../FiltersUtils';
import {SET_PROJECT} from "../../redux/types";

const RebillRatesFilters = (props: any) => {
  const {projectsList, productsList, ...rest} = props;
  setProjectFromStore(props);


  return (
    <Filter {...rest}>
      {renderFilterSelectInput('project_id', projectsList, SET_PROJECT)}
      {renderFilterSelectInput('product_id', productsList, "uuid")}
      <TextInput source="product_uuid" resettable alwaysOn parse={(value: string) => value.trim()}/>
    </Filter>
 );
};

export default RebillRatesFilters;
