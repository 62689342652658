import { SelectInput } from "react-admin";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

export const renderFilterSelectInput = (
  fieldName: string,
  choices: object[],
  actionType?: string,
  optionText?: string,
) => {
  let onChange = undefined;
  if (actionType) {
    const dispatch = useDispatch();
    onChange = (e: any) => dispatch({
      type: actionType,
      payload: e.target?.value || null,
    })
  }

  return (
   <SelectInput
    key={fieldName}
    source={fieldName}
    choices={choices}
    allowEmpty={false}
    resettable
    alwaysOn
    optionText={optionText}
    onChange={onChange}
  />
  )
};

export const setProjectFromStore = (props: any) => {
  if (!props.filterValues.project_id) {
    const selected = useSelector((state: {project: number}) => state.project);
    if (!!selected) {
      props.filterValues.project_id = selected;
    }
  }
}