import React from 'react';
import {Filter, TextInput} from 'react-admin';
import {renderFilterSelectInput, setProjectFromStore} from '../FiltersUtils';
import { getChoicesList } from 'utils/common';
import {SET_FLOW_TYPE, SET_PROJECT} from "../../redux/types";

const FlowsFilters = (props: any) => {
 const { serverConstants, ...rest } = props;
 setProjectFromStore(props);

 return (
  <Filter {...rest}>
   {renderFilterSelectInput('project_id', serverConstants?.projectsList, SET_PROJECT)}
   {renderFilterSelectInput('type', getChoicesList(Object.values(serverConstants?.flow_type?.items)), SET_FLOW_TYPE)}
   <TextInput source="name" resettable alwaysOn parse={(value: string) => value.trim()}/>
   <TextInput source="screen_name" resettable alwaysOn parse={(value: string) => value.trim()}/>
  </Filter>
 );
};

export default FlowsFilters;
