import React, {useState} from 'react';
import {
    TextField,
    FunctionField,
    EditButton,
    useGetList, DeleteWithConfirmButton, CloneButton,
} from 'react-admin';

import {
    If,
    Choose,
    When,
    Otherwise
} from 'typings/tsx-control-statements.d';

import {getDefaultRowStyle, getItemById} from 'utils/common';
import {withServerConstants} from 'providers/ServerConstantsProvider';
import CreateScreenModal from 'modals/CreateScreenModal';
import List from 'components/List';
import Loader from 'components/Loader';
import ScreensPanel from 'panels/Screens/ScreensPanel';
import CreateWithModalAction from 'actions/CreateWithModalAction';
import ScreensFilters from 'filters/Screens/ScreensFilters';
import {PAYMENT} from 'constants/screens';
import {ControllerPropsInterface} from 'interfaces/ControllerPropsInterface';
import {Record} from 'ra-core';
import {isNotViewer} from "../../utils/authProviderProvider";

const Screens = (props: ControllerPropsInterface) => {
    const {serverConstants, permissions, ...rest} = props;

    const [isOpen, setIsOpen] = useState(false);

    const projectsList = serverConstants?.projectsList;
    const productsResponse = useGetList('products', {page: 1, perPage: 9999});

    return (
        <Choose>
            <When condition={!!projectsList && productsResponse.loaded}>
                <>
                    <List
                        rowClick="expand"
                        expand={({record}) => (
                            <ScreensPanel
                                productsList={productsResponse.data}
                                record={record}
                            />
                        )}
                        actions={isNotViewer(permissions) ? (
                            <CreateWithModalAction
                                openModal={() => setIsOpen(true)}
                            />
                        ) : false}
                        rowStyle={(record: Record) => getDefaultRowStyle(serverConstants, record.project_id)}
                        filters={<ScreensFilters serverConstants={serverConstants}/>}
                        sort={{field: 'created_at', order: 'DESC'}}
                        {...rest}
                    >
                        <FunctionField
                            source="project_id"
                            render={(record: Record | undefined) => getItemById(projectsList, record?.project_id)?.name}
                        />
                        <TextField
                            source="name"
                        />
                        <TextField
                            source="client_id"
                            label="ID (Client)"
                        />
                        <TextField
                            source="type"
                        />
                        <TextField source="experiments_count"/>
                        <If condition={isNotViewer(permissions)}>
                            <FunctionField
                                render={(record: Record | undefined) => (
                                    record?.experiments_count === 0 && (
                                        <EditButton record={record} basePath="/screens"/>
                                    )
                                )}
                            />
                            <CloneButton label="Copy"/>
                            <DeleteWithConfirmButton confirmTitle={'Delete screen'}/>
                        </If>
                    </List>
                    <If condition={isNotViewer(permissions)}>
                        <CreateScreenModal
                            modalTitle="Create Screen Modal"
                            isOpen={isOpen}
                            onCloseModal={() => setIsOpen(false)}
                            serverConstants={serverConstants}
                            {...rest}
                        />
                    </If>
                </>
            </When>
            <Otherwise>
                <Loader/>
            </Otherwise>
        </Choose>
    );
};

export default withServerConstants(Screens);
