import React, { useState } from 'react';
import {
 SimpleForm,
 NumberInput,
 SimpleFormIterator,
 FormDataConsumer,
 ArrayInput,
 TextInput,
 Toolbar,
 SaveButton,
 useNotify, required,
} from 'react-admin';

import Modal from 'components/Modal';
import './SplitExperimentTrafficModal.scss';
import CheckIcon from '@material-ui/icons/Check';
import {validateCyrillic, validateTraffic} from "../../validators/Experiments/ExperimentsValidator";
import dataProvider from 'providers/DataProvider';


const CustomToolbar = (isError: boolean) => {
 const notify = useNotify();
 const handleSave = (data: any) => {
  // @ts-ignore
  dataProvider.splitExperimentTraffic({
   variants: data.variants.map((variant: any) => ({
    id: variant.id,
    traffic_percent: variant.traffic_percent,
   })),
   id: data.id,
  })
   .then(() => notify('Saved successfully.', 'success'))
   .catch((error: any) => {
    console.error(error);
    notify('Something went wrong', 'warning');
   });
 };

 return (
  <Toolbar className="CustomToolbar">
   <SaveButton
    label="Continue"
    icon={<CheckIcon />}
    disabled={isError}
    onSave={(data) => handleSave(data)}
   />
  </Toolbar>
 );
};

const SplitExperimentTrafficModal = ({ isOpen, onCloseModal, modalTitle, record }: any) => {
 const [isError, setIsError] = useState(false);

 return (
  <Modal
   isOpen={isOpen}
   onCloseModal={onCloseModal}
   modalTitle={modalTitle}
  >
   <SimpleForm
    toolbar={CustomToolbar(isError)}
    initialValues={record}
   >
    <ArrayInput source="variants">
     <SimpleFormIterator disableAdd disableRemove disableReordering>
      <FormDataConsumer>
       {({ getSource, formData }) => (
        <>
         <TextInput
          key="name"
          source={getSource ? getSource('name') : 'default'}
          label="Name"
          disabled
          className="SplitExperimentTrafficModal__input"
          parse={(value: string) => value.trim().replace(/ /g,"_")}
          validate={[validateCyrillic]}
         />
         <NumberInput
          key="traffic_percent"
          source={getSource ? getSource('traffic_percent'): 'default'}
          label="Traffic %"
          min={0}
          max={100}
          validate={validateTraffic}
         />
        </>
       )}
      </FormDataConsumer>
     </SimpleFormIterator>
    </ArrayInput>
   </SimpleForm>
  </Modal>
 );
};

export default SplitExperimentTrafficModal;
