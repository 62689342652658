import React from 'react';

import ExperimentsNormalizer from 'normalizer/ExperimentsNormalizer';
import ExperimentsForm from 'forms/Experiments';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import CreateFromModal from "forms/CreateFromModal";

const ExperimentsAdd = (props: ControllerPropsInterface) => (
 <CreateFromModal {...props}>
  <ExperimentsForm
    actionType={ActionType.ADD}
    onSuccess={defaultOnSuccess}
    transform={ExperimentsNormalizer.create}
   {...props}
  />
 </CreateFromModal>
);

export default ExperimentsAdd;
