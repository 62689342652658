import React, {useEffect, useState} from 'react';
import {HttpError, useGetOne, useNotify} from 'react-admin';
// @ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Button, Grid, Typography} from '@material-ui/core';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
    Choose,
    When,
    Otherwise
} from 'typings/tsx-control-statements.d';

import {getItemById} from 'utils/common';
import {Column, Table} from 'components/Table';
import Loader from 'components/Loader';

import './ExperimentsPanel.scss';
import {ServerConstants} from '../../interfaces/CommonInterface';
import {ExperimentsPanelInterface} from 'interfaces/Experiments/ExperimentsPanelInterface';
import dataProvider from "../../providers/DataProvider";
import RunTestModal from "../../modals/RunTestModal";

const generateExperimentLink = (record: any, serverConstants: ServerConstants) => {
    const branch = getItemById(serverConstants.branchesList, record.branch_id);
    const project = getItemById(serverConstants.projectsList, branch.project_id);

    return `https://${project.domain}?branch-name=${branch.url_segment}&test-name=${record.system_name}`;
};
const findObjectById = <T extends { id: number }>(array: T[] | undefined, idToFind: number): T | null => {
    if (!Array.isArray(array)) {
        return null;
    }
    const foundObject = array.find(obj => obj.id === idToFind);
    return foundObject || null;
};

const ExperimentsPanel = (props: ExperimentsPanelInterface) => {
    const notify = useNotify();
    const {record, serverConstants, setIsOpenVariant, setVariantData} = props;
    const screenIds = record.variants.map((item: any) => item.first_page_id)
    const onboardingFlowIds = record.variants.map((item: any) => item.onboarding_flow_id);
    const paymentFlowIds = record.variants.map((item: any) => item.payment_flow_id);
    const [screensList, setScreensList] = useState([]);
    const [flowList, setFlowList] = useState([]);
    const [isScreensLoaded, setScreensIsLoaded] = useState(true);
    const [isFlowsLoaded, setFlowsIsLoaded] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result: any = await dataProvider.getListByIds('screens-filtered', {ids: screenIds});
                setScreensList(result.data.items);
                setScreensIsLoaded(false)
            } catch (error) {
                console.log('Error while fetching the list of screens:', error);
            }
        };

        fetchData();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result: any = await dataProvider.getListByIds('flow-filtered', {ids: [...onboardingFlowIds, ...paymentFlowIds]});
                setFlowList(result.data.items);
                setFlowsIsLoaded(false)
            } catch (error) {
                console.log('Error while fetching the list of flow:', error);
            }
        };

        fetchData();
    }, [])

    return <>
        <Choose>
            <When condition={!!serverConstants && !isScreensLoaded && !isFlowsLoaded}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6" gutterBottom>
                            {record.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <CopyToClipboard
                            text={generateExperimentLink(record, serverConstants)}
                            onCopy={() => {
                                notify('Copied to clipboard', 'success');
                            }}
                        >
                            <Button startIcon={<FileCopyIcon/>}>
                                Copy Link
                            </Button>
                        </CopyToClipboard>
                        <Button
                            startIcon={<ViewWeekIcon/>}
                            onClick={(e) => {
                                e.preventDefault();
                                setVariantData(record);
                                setIsOpenVariant(true);
                            }}
                        >
                            Details
                        </Button>
                        <Button
                            href={generateExperimentLink(record, serverConstants)}
                            startIcon={<CallMadeIcon/>}
                            target="_blank"
                        >
                            Run
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} container alignContent="flex-end">
                        System name: {record.system_name}
                    </Grid>
                    <Grid item xs={6} container alignContent="flex-end">
                        Creation Date: {new Date(record.created_at).toLocaleDateString()}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} container alignContent="flex-end">
                        Test start Date: {new Date(record.updated_at).toLocaleDateString()}
                    </Grid>
                    <Grid item xs={6} container alignContent="flex-end">
                        Created By: {record.created_by}
                    </Grid>
                    <Grid item xs={6} container alignContent="flex-end">
                        Last Updated By: {record.last_updated_by || record.created_by}
                    </Grid>
                    <Grid item xs={10} container alignContent="flex-end">
                        {record.description}
                    </Grid>
                </Grid>
                <div className="ExperimentsPanel__spacer">&nbsp;</div>
                <Table items={record.variants}>
                    <Column
                        header="Name"
                        tooltip="Name of variant"
                        render={({name}) => name}
                    />
                    <Column
                        header="Capacity"
                        tooltip="Capacity"
                        render={({traffic_percent}) => `${traffic_percent}%`}
                    />
                    <Column
                        header="R / SS"
                        tooltip="Requests count / Sample Size(Installs)"
                        render={({request_counter, installs}) => `${request_counter || '-'}/${installs || '-'}`}
                    />
                    <Column
                        header="First Page"
                        tooltip="First Page"
                        render={({first_page_id}) => {
                            const foundObject = findObjectById(screensList, first_page_id);
                            return (foundObject as unknown as { name?: string })?.name;
                        }}
                    />
                    <Column
                        header="OB Flow"
                        tooltip="Onboarding Flow name"
                        render={({onboarding_flow_id}) => {
                            const foundObject = findObjectById(flowList, onboarding_flow_id);
                            return (foundObject as unknown as { name?: string })?.name;
                        }}
                    />
                    <Column
                        header="Payment Flow"
                        tooltip="Payment Flow name"
                        render={({payment_flow_id}) => {
                            const foundObject = findObjectById(flowList, payment_flow_id);
                            return (foundObject as unknown as { name?: string })?.name;
                        }}
                    />
                    <Column
                        header="ARPU / S2P"
                        tooltip="ARPU / Start2Purchase"
                        render={({
                                     arpu,
                                     installs,
                                     purchasers,
                                 }) => {
                            const s2p = purchasers && installs ? ((purchasers / installs) * 100).toFixed(2) : 0;
                            return `${arpu?.toFixed(2) || '-'} / ${s2p}%`;
                        }}
                    />
                    <Column
                        header="S2OB / OB2L / L2P"
                        tooltip="Start2Onboarding / Onboarding2lead / Lead2Purchase"
                        render={({
                                     installs,
                                     second_events,
                                     leads,
                                     purchasers,
                                 }) => {
                            const s2ob = second_events && installs ? ((second_events / installs) * 100).toFixed(2) : 0;
                            const ob2l = leads && second_events ? ((leads / second_events) * 100).toFixed(2) : 0;
                            const l2p = purchasers && leads ? ((purchasers / leads) * 100).toFixed(2) : 0;
                            return `${s2ob}% / ${ob2l}% / ${l2p}%`;
                        }}
                    />
                    <Column
                        header="Prob. ARPU / S2P"
                        tooltip="Probability ARPU / Probability Start2Purchase"
                        render={({
                                     test_prob_arpu,
                                     test_prob_conversion,
                                 }) => `${test_prob_arpu || '-'} / ${test_prob_conversion || '-'}`}
                    />
                    <Column
                        header="AQA"
                        tooltip="Run GitLab pipeline"
                        render={(item) => <Button onClick={() => {
                            setIsOpen(true)
                            setItem(item)
                            console.log(isOpen)
                            console.log(item)
                            // handleRunTest(item)
                        }}
                                                  startIcon={<CallMadeIcon/>}
                        >
                            Run test
                        </Button>}
                    />

                </Table>

            </When>
            <Otherwise>
                <Loader/>
            </Otherwise>
        </Choose>
        <RunTestModal
            modalTitle="Test variables"
            isOpen={isOpen}
            onCloseModal={() => setIsOpen(false)}
            serverConstants={serverConstants}
            item={item}
            {... record}
        />
    </>

};

export default ExperimentsPanel;
