/**
 * Payment Flow
 */
const makeScreenObject = (index, screenId, screens) => {
 const isEven = index % 2 === 0;
 const parentId = ((isEven ? index : index + 1) / 2) - 1;

 return {
  screen_id: screenId,
  is_paid: index !== 0 ? !isEven : null,
  parent_id: index !== 0 ? screens[parentId]?.screen_id : null,
 };
};

export const getChildElement = (list, parentId, isPaid) => list.find(
 (value) => value.parent_id === parentId && value.is_paid === isPaid,
);

export const getPaymentFlowList = (list) => {
 const newList = [];
 let parentIndex = 0;
 let parent = list.find((value) => value.parent_id === null);
 newList[parentIndex] = parent;

 while (parentIndex < (list.length * 2)) {
  if (parent) {
   newList[(parentIndex + 1) * 2 - 1] = getChildElement(list, parent.screen_id, true);
   newList[(parentIndex + 1) * 2] = getChildElement(list, parent.screen_id, false);
  }

  parentIndex++;
  parent = newList[parentIndex];
 }
 return newList;
};

// get structure after create action and form new structure
// empty screen item skip
export const createAddPaymentFlowStructure = (payload) => {
 const payloadList = [];

 for (let i = 0; i < payload.payment_screens.length; i++) {
  if (payload.payment_screens[i]?.screen_id) {
   payloadList.push(makeScreenObject(i, payload.payment_screens[i]?.screen_id, payload.payment_screens));
  }
 }

 return payloadList;
};

export const createEditPaymentFlowStructure = (payload) => {
 const paymentList = [];
 const { payment_screens, ...rest } = payload;

 payment_screens.map((item, index) => {
  if (item && item.screen_id) {
   paymentList[index] = makeScreenObject(index, item.screen_id, payment_screens);
  }

  return null;
 });

 return { ...rest, payment_screens: paymentList.filter((item) => item !== null) };
};
