import React from 'react';
import {Filter, SelectInput, TextInput} from 'react-admin';
import {renderFilterSelectInput, setProjectFromStore} from '../FiltersUtils';
import { getChoicesList } from 'utils/common';
import {SET_PROJECT} from "redux/types";
import {PROCESSORS} from "../../constants/common";

const ProductsFilters = (props: any) => {
 const { serverConstants, ...rest } = props;
 setProjectFromStore(props);

  return (
    <Filter {...rest}>
      {renderFilterSelectInput('project_id', serverConstants?.projectsList, SET_PROJECT)}
      {renderFilterSelectInput('type', getChoicesList(Object.values(serverConstants?.product_type?.items)))}
      <TextInput source="name" resettable alwaysOn parse={(value: string) => value.trim()}/>
      <TextInput source="uuid" resettable alwaysOn parse={(value: string) => value.trim()}/>
      {renderFilterSelectInput('processor', PROCESSORS)}
   <TextInput source="product_code" resettable alwaysOn parse={(value: string) => value.trim()}/>
  </Filter>
 );
};

export default ProductsFilters;
