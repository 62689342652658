import React from 'react';
import {
 Edit,
} from 'react-admin';

import ScreensNormalizer from 'normalizer/ScreensNormalizer';
import ScreensForm from 'forms/Screens';
import { ControllerPropsInterface } from 'interfaces/ControllerPropsInterface';
import { ActionType } from 'interfaces/CommonInterface';
import { defaultOnSuccess } from '../CommonController';

const ScreensEdit = (props: ControllerPropsInterface) => (
 <Edit {...props}>
  <ScreensForm
    actionType={ActionType.EDIT}
    onSuccess={defaultOnSuccess}
    redirect="list"
    transform={ScreensNormalizer.update}
    {...props}
  />
 </Edit>
);

export default ScreensEdit;
